import api from "./api"
import { Zapiska, ZapiskaRequest } from "../types"
import { UUID } from "crypto";

const PATH = '/notes';

export const NotesAPI = {
	create: async function (note:Zapiska, cancel = false) {
		var body = {} as ZapiskaRequest
		body.pid = note.pid as string
		body.items = note.items as string[]
		if (note.sub!=="") {
			body.sub = note.sub;
		}
		if (note.comment!=="") {
			body.cmnt = note.comment;
		}
		return await api.post(
			PATH,
			body
		)
	},
	getByID: async function (id:UUID, cancel = false) {
		return await api.get<Zapiska>(
			PATH+'/'+id
		)
	},

	getList: async function (orderID:UUID, cancel = false) {
		return await api.get<Zapiska[]>(
			PATH+'?oid='+orderID
		)
	},
	update: async function (note:ZapiskaRequest, cancel = false) {
		var body = {} as ZapiskaRequest
		if (note.items && note.items.length>0) {
			body.items = note.items
		}
		if (note.cmnt!=="") {
			body.cmnt = note.cmnt
		}
		
		return await api.patch(
			PATH+'/'+note.id,
			body
		)
	},
	delete: async function (id:UUID, cancel = false) {
		return await api.delete(
			PATH+'/'+id
		)
	},
}