import React, { useState, useContext, useEffect  } from "react";
import { DataContext } from "../context/DataContext";
import {
	ModalPage,
	FormLayout,
	FormItem,
	Button,
	ButtonGroup,
	RadioGroup,
	Radio,
	ModalPageHeader,
	Div,
	Textarea,
	PanelHeaderBack,
} from "@vkontakte/vkui";
import { NavProp } from "../types";
import {
	useParams,
	useRouteNavigator,
	//useEnableSwipeBack,
} from "@vkontakte/vk-mini-apps-router";
import { DEFAULT_VIEW_PANELS } from "../routes";
//import crossRed from "../img/cross-red.svg";
//import crossBlack from "../img/cross-black.svg";

import { CrossRed, CrossBlack } from "../components/images";

type VariationModalProps = NavProp & {
	setSubtitle:any;
};

	export const TrebaVariationModal = ({ nav, setSubtitle }: VariationModalProps) => {
		const { getProduct } = useContext(DataContext);
		const routeNavigator = useRouteNavigator();
		const { cat, pid } : any = useParams({ panel: nav }) ?? {};
		const [variation, setVariation] = useState("");
		const [disable, setDisable] = useState(true);
		const [custom, setCustom] = useState(false);
		//const [modalBefore, setModalBefore] = useState(<Image size={22} src={cat === "zdravie" ? crossRed : crossBlack} />);
		const [modalBefore, setModalBefore] = useState(cat === "zdravie" ? <CrossRed width={22} height={22} style={{marginLeft:"8px", borderRadius:"4px", verticalAlign:"middle"}} /> : <CrossBlack width={22} height={22} style={{marginLeft:"8px", borderRadius:"4px", verticalAlign:"middle"}} />);
	
		const product = getProduct(cat,pid);

		useEffect(() => {
			variation.length>9?setDisable(false):setDisable(true);
		}, [variation]);

		useEffect(() => {
			if (custom) {
				setModalBefore(<PanelHeaderBack onClick={() => setCustom(false)} />);
			} else {
				//setModalBefore(<Image size={22} src={cat === "zdravie" ? crossRed : crossBlack} />);
				setModalBefore(cat === "zdravie" ? <CrossRed width={22} height={22} style={{marginLeft:"8px", borderRadius:"4px", verticalAlign:"middle"}} /> : <CrossBlack width={22} height={22} style={{marginLeft:"8px", borderRadius:"4px", verticalAlign:"middle"}} />);
			}
		}, [custom, cat]);
	
		const saveSubtitle = () => {
			setSubtitle(variation);
			setVariation("");
			routeNavigator.replace(`/${DEFAULT_VIEW_PANELS.NEW}/${cat}/${pid}`);
		}
		const closeModal = () => {
			setVariation("");
			setSubtitle("");
			routeNavigator.replace(`/${DEFAULT_VIEW_PANELS.NEW}/${cat}`);
		}

		const textareaChange = (e:any) => {
			setVariation(e.target.value)
		}
		
		return (
			<ModalPage
				id="variation"
				header={
					<ModalPageHeader
						before={modalBefore}
						separator={true}
					>{product.title}</ModalPageHeader>
				}
				dynamicContentHeight
				onClose={closeModal}
			>
				
				{custom && 
				
				<FormLayout>
					<FormItem>
						<Textarea rows={1} minLength={10} maxLength={200} placeholder="Кому" autoFocus onInput={textareaChange} />
					</FormItem>

				</FormLayout>
				}
				{!custom && 
				<RadioGroup>
				{product.variations?.map((val, i) => (
					<Radio key={i} name="variation" value={val.title} onChange={()=>setVariation(val.title)}>{val.title}</Radio>
				))}
					<Radio key="custom" name="variation" onChange={()=>{setVariation("");setCustom(true);}}>Свой вариант</Radio>
				</RadioGroup>
				}
				<Div>
				<ButtonGroup mode="horizontal" stretched>
					<Button mode="primary" stretched disabled={disable} onClick={saveSubtitle}>Выбрать</Button>
					<Button mode="tertiary" appearance="negative" stretched onClick={closeModal}>Отмена</Button>
				</ButtonGroup>
				</Div>
			</ModalPage>
		);
};
