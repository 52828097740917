import React, { createContext, useState, useEffect } from 'react'
import { ProductList, Product } from '../types';
import { UUID } from "crypto";

type InitDataContext = {
    products:ProductList,
	getProductsByCat:(cat:string) => Product[],
	getProduct:(cat:string, id:UUID) => Product,
}

export const DataContext = createContext({} as InitDataContext)




export const DataProvider = ({children}:any) => {
  const [products, setProducts] = useState(localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')  || '{}') : {})
  
  const getProductsByCat = (cat:string) => {
	return products[cat].products as Product[] ?? [];
  };

  const getProduct = (cat:string, id:UUID) => {
	let productList = getProductsByCat(cat);
	return productList.filter((item:Product) => item.id === id)[0] as Product ?? {};
  };

  

  useEffect(() => {
    localStorage.setItem("products", JSON.stringify(products));
  }, [products]);

  useEffect(() => {
    const products = localStorage.getItem("products");
    if (products) {
      setProducts(JSON.parse(products));
    }
  }, []);



  return (
    <DataContext.Provider
      value={{
        products,
		getProductsByCat,
		getProduct,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};