import React, { useContext } from "react";
import {
	ModalPage,
	Button,
	Group,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	PanelHeaderContent,
	//Avatar,
	CardGrid,
	ContentCard,
	Spacing,
	usePlatform,
	Platform,
	Div,
	Title,
} from "@vkontakte/vkui";
import { NavProp, GoFunctionProp } from "../types";
import { CartContext } from "../context/ShoppingCartContext";
import {
	useParams,
	useRouteNavigator
} from "@vkontakte/vk-mini-apps-router";
import {CART_VIEW_PANELS} from "../routes"

import {ReactComponent as ZdravieTitle} from "../img/zdravie.svg";
import {ReactComponent as UpokoenieTitle} from "../img/upokoenie.svg";
import './noteNames.css';

import { CrossRed, CrossBlack } from "../components/images";

type CartViewNoteProps = NavProp & GoFunctionProp;

const CartViewNote = ({ nav, go }: CartViewNoteProps) => {
	
	const platform = usePlatform();
	const isVKCOM = platform === Platform.VKCOM;
	const { noteId }: any = useParams({ panel: nav }) ?? {};
	const { getNote } = useContext(CartContext);
	const note = getNote(noteId) ?? {}
	const routeNavigator = useRouteNavigator();
	
	// как-то обработать неверный id
	if (!note.id) return (
		<ModalPage>ошибка</ModalPage>
	)
		
	return (
		<Panel id="cart_view" nav={nav} >
			<PanelHeader before={<PanelHeaderBack onClick={() => routeNavigator.replace('/cart/')} />}>
			<PanelHeaderContent
            	status={note.sub?"":note.title}
			   before={note.cat === "zdravie" ? <CrossRed width={36} height={36} style={{borderRadius:"18px", verticalAlign:"middle"}} /> : <CrossBlack width={36} height={36} style={{borderRadius:"18px", verticalAlign:"middle"}} />}
			>
          {note.cat === "zdravie" ?
				<ZdravieTitle style={{ maxHeight: "1em", height:"1em", width:"auto", margin: "0 0 -0.2em -0.5em" }}/> :
				<UpokoenieTitle style={{ maxHeight: "1em", height:"1em", width:"auto", margin: "0 0 -0.2em 0" }}/>

			}
          </PanelHeaderContent>
		  </PanelHeader>
			<Group>
			
			<div style={isVKCOM?{width:"70%", margin: "auto"}:{}}>
			{note.sub?
				<div style={{ textAlign: "center" }}>
				<Title level="2">{note.title}</Title>
				<Title level="3" weight="3">{note.sub}</Title>
				</div>
			:""
			}
			<ol className="noteNames">
			{note.items?.map((item, index) => {
					return (
						<li className={note.cat} key={index}><i>{item}</i></li>
					);
			})}
			</ol>
			{note.comment && note.comment.length>0 &&
			<Group mode="plain">
				<Spacing/>
				<CardGrid
					size="l"
				>
				<ContentCard
              		subtitle="Комментарий"
					text={note.comment}
          		 />		
				</CardGrid>
			</Group>
			
			}
			</div>
			<Div style={isVKCOM?{width:"60%", margin: "auto"}:{}}>
					<Button
						id="editNote"
						stretched
						size="l"
						mode="primary"
						onClick={() => go(`/${CART_VIEW_PANELS.EDIT}/${note.id}`)}
					>
						Редактировать
					</Button>
				</Div>
			
			</Group>
		</Panel>
	);
};
export default CartViewNote;