import React, { useContext, useState, useEffect } from "react";
import {
	Group,
	Panel,
	PanelHeader,
	PanelHeaderBack,
} from "@vkontakte/vkui";
import { NavProp, GoFunctionProp } from "../types";
import { CartContext } from "../context/ShoppingCartContext";
import { HistoryContext } from "../context/HistoryContext";

import {
	useParams,
	useRouteNavigator
} from "@vkontakte/vk-mini-apps-router";
import YooWidget from "../components/YooKassa";

type CartCheckoutProps = NavProp & GoFunctionProp;

const CartCheckout = ({ nav, go }: CartCheckoutProps) => {
	const { orderID, token }: any = useParams({ panel: nav }) ?? {};
	const [ done, setDone ] = useState<boolean>(false)
	const { clearCart } = useContext(CartContext);
	const { clearHistory } = useContext(HistoryContext);

	const routeNavigator = useRouteNavigator();

	const onComplete = (successed:boolean) => {
		if (successed) {
			clearCart();
			clearHistory();
		}
		setDone(true);
	}

	useEffect(() => {
		if (done) {
			routeNavigator.replace(`/cart/order/${orderID}`);
		}
	},[done]);

	return (
		<Panel id="cart_checkout" nav={nav}>
			<PanelHeader before={<PanelHeaderBack onClick={() => routeNavigator.replace('/cart/')} />}>
				Оплата
			</PanelHeader>
			<Group>
				{token && <YooWidget token={token as string} onComplete={onComplete} /> }
			</Group>
		</Panel>
	);

};
export default CartCheckout;