export const trebaCats = {"zdravie":'О здравии',"upokoenie":'О упокоении'};
export const commentMaxLen = 200;
export const nameMaxLen = 50;

export const axiosErrors = {
	"ERR_NETWORK": "Ошибка соединения. Проверьте подключение к сети",
	"ECONNABORTED": "Превышено время ожидания",
	"ERR_CANCELED": "Запрос отменен",
	"ERR_BAD_REQUEST": "400 Bad Request",
	"ERR_UNAUTHORIZED": "401 Доступ запрещен",
	"ERR_FORBIDDEN": "403 Доступ запрещен",
	"ERR_NOT_FOUND": "404 Not Found",
	"ERR_INTERNAL_SERVER": "500 Внутренняя ошибка сервера",
	"ERR_BAD_RESPONSE": "502 Bad Response",
	"ERR_SERVICE_UNAVAILABLE": "503 Сервис недоступен",
	"ERR_GATEWAY_TIMEOUT": "504 Gateway Timeout",
	"ERR_GENERIC": "An error occurred"
  };