import React, { useState, useContext, useEffect } from "react";
import {
	FormLayout,
	FormItem,
	Input,
	ButtonGroup,
	Button,
	Text,
	IconButton,
	Textarea,
	Footnote,
} from "@vkontakte/vkui";
import { Icon12Add } from "@vkontakte/icons";
import { FavoritesContext } from "../context/FavoritesContext";
import { Favorite } from "../types";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { Icon16Clear } from "@vkontakte/icons";
import { FavoritesAPI } from "../api/favorites";
import { loadingSpinner } from "../components/spinners";
import { nameMaxLen, commentMaxLen } from "../constants";
import { useNotification } from '../context/NotificationContext';

type FavoriteEditFormProps = {
	note:Favorite;
};

export const FavoriteEditForm = (props: FavoriteEditFormProps) => {
	const note = props.note;
	const { notify, apiErrorNotify } = useNotification();
	const [values, setValues] = useState<string[]>(note.items || []);
	const [description, setDescription] = useState(note.description?note.description:"");

	const { updateNoteInFav } = useContext(FavoritesContext);
	const routeNavigator = useRouteNavigator();

	const addFields = () => {
		setValues(values.concat(Array(5).fill("")));
	};

	function timeout(delay: number) {
		return new Promise((res) => setTimeout(res, delay));
	}

	const handleChange =
		(index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
			let newArr = [...values];
			newArr[index] = e.target.value;
			setValues(newArr);
		};

	const handleDelete = (index: number) => {
		setValues(values.filter((value, i) => i !== index));
	};

	
	function isArraysEqual(firstArray:any, secondArray:any) {
		return firstArray.toString() === secondArray.toString();
	}

	const [toUpdate, setToUpdate] = useState(false);

	useEffect(() => {
		if (!isArraysEqual(note.items, values.filter(function (el) {return el !== ""}))) {
			setToUpdate(true);
		}
	}, [values]);

	useEffect(() => {
		if (note.description !== description) {
			setToUpdate(true);
		}
	}, [description]);

	async function update(note:Favorite) {
		note.items = values.filter(function (el) {return el !== ""});
		note.description = description;

		await routeNavigator.showPopout(loadingSpinner)
		await FavoritesAPI.update(note)
		.then((result) => {
			if (result.data?.hash!=="") {
				note.hash = result.data.hash;
			}
			updateNoteInFav(note);
			notify('Записка успешно сохранена','success');
			timeout(1000);
			routeNavigator.back();
		}).catch((error) => {
			apiErrorNotify(error);
		}).finally(() => {
			return routeNavigator.hidePopout()
		}
	);
		
	};

	//const [snackbar, setSnackbar] = useState<any | null>(null);

	const descriptionChange = (e:any) => {
		setDescription(e.target.value)
	}
	
	return (
		<FormLayout id={note.id}>
			<FormItem top="Название:">
					<Input id="title" name="title" onInput={descriptionChange} defaultValue={note.title?note.title:""} maxLength={nameMaxLen} />
			</FormItem>
			<FormItem top="Описание:" bottom={<Footnote style={{ textAlign: "right", marginTop:-28, position:"relative", fontSize:"0.8em", right:7, zIndex:9999 }}>{description.length} / {commentMaxLen}</Footnote>}>
					<Textarea id="description" style={{paddingBottom:8}} rows={1} name="description" onInput={descriptionChange} defaultValue={note.description?note.description:""} maxLength={commentMaxLen} />
			</FormItem>
			<FormItem top="Имена:" style={{paddingBottom:0}} />
			{values.map((val:string, index) => {
				return (
					<FormItem key={index} style={{ flexGrow: 1, flexShrink: 1 }}>
						<Input
							key={index}
							value={val}
							maxLength={nameMaxLen}
							before={<Text>{index + 1}.</Text>}
							onChange={handleChange(index)}
							after={val.length>0 && 
								<IconButton
									hoverMode="opacity"
									aria-label="Удалить"
									onClick={() => handleDelete(index)}
								>
									<Icon16Clear />
								</IconButton>
							}
						/>
					</FormItem>
				);
			})}
			<ButtonGroup
				mode="horizontal"
				align="center"
				gap="none"
				style={{ width: "100%" }}
			>
				<Button
					appearance="accent"
					size="m"
					align="center"
					mode="tertiary"
					before={<Icon12Add />}
					onClick={addFields}
				>
					добавить еще имена
				</Button>
			</ButtonGroup>
			<FormItem>
				<ButtonGroup
					mode="horizontal"
					align="center"
					gap="none"
					style={{ width: "100%" }}
				>
					<ButtonGroup
						mode="vertical"
						align="center"
						gap="s"
						style={{ minWidth: 328 }}
					>
						<Button
							id="updateNote"
							stretched
							size="l"
							mode="primary"
							disabled={!toUpdate}
							onClick={() => update(note)}
						>
							Сохранить
						</Button>	
						<Button
							id="cancel"
							stretched
							size="m"
							mode="tertiary"
							appearance="negative"
							onClick={() => routeNavigator.back()}
						>
							Отмена
						</Button>
					</ButtonGroup>
				</ButtonGroup>
			</FormItem>
			{/* snackbar */}
		</FormLayout>
	);
};