import api from "./api"
import { OrderStatus, OrderList, NewOrder } from "../types"
import { UUID } from "crypto";

const PATH = '/orders';

export const OrderAPI = {
	create: async function (noteIDs:UUID[], isMobile:boolean, cancel = false) {
		const data = {
			ids: noteIDs,
			m:  isMobile,
		} as NewOrder;
		return await api.post(
			PATH,
			data
		)
	},
	/* create: async function (noteIDs:UUID[], cancel = false) {
		return await api.post(
			PATH,
			noteIDs
		)
	}, */
	get: async function (id:UUID, cancel = false) {
		return await api.get<OrderStatus>(
			PATH+'/'+id
		)
	},
	getList: async function (page:number, cancel = false) {
		if (!page) {
			page=1
		}
		return await api.get<OrderList>(
			PATH+'?page='+page
		)
	},
}