import api from "./api"
import { Favorite, FavoriteRequest } from "../types"
import { UUID } from "crypto";

const PATH = '/favorites';

export const FavoritesAPI = {
	create: async function (id:UUID, cancel = false) {
		return await api.post(
			PATH,
			id
		)
	},
	getByID: async function (id:UUID, cancel = false) {
		return await api.get<FavoriteRequest>(
			PATH+'/'+id
		)
	},
	getList: async function (cancel = false) {
		return await api.get<FavoriteRequest[]>(
			PATH
		)
	},
	update: async function (note:Favorite, cancel = false) {
		var body = {} as FavoriteRequest
		
		body.title = note.title as string;
		body.desc = note.description as string;
		body.items = note.items as string[];

		return await api.put(
			PATH+'/'+note.id,
			body
		)
	},
	delete: async function (id:UUID, cancel = false) {
		return await api.delete(
			PATH+'/'+id
		)
	},
}