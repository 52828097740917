import React, { useContext } from "react";
import {
	ModalPage,
	FormLayout,
	FormItem,
	Button,
	Group,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	PanelHeaderContent,
	//Avatar,
	ButtonGroup,
	CardGrid,
	ContentCard,
	Spacing,
} from "@vkontakte/vkui";
import { NavProp, GoFunctionProp } from "../types";
import { FavoritesContext } from "../context/FavoritesContext";
import {
	useParams,
	useRouteNavigator
} from "@vkontakte/vk-mini-apps-router";
import { FAVORITE_VIEW_PANELS} from "../routes"
//import zdravie from "../img/zdravie.svg";
//import upokoenie from "../img/upokoenie.svg";

import {ReactComponent as ZdravieTitle} from "../img/zdravie.svg";
import {ReactComponent as UpokoenieTitle} from "../img/upokoenie.svg";

import './noteNames.css';

import { CrossRed, CrossBlack } from "../components/images";

type FavoritesViewProps = NavProp & GoFunctionProp;

export const FavoritesView = ({ nav, go }: FavoritesViewProps) => {
	const { noteId }: any = useParams({ panel: nav }) ?? {};
	const { getFavNote } = useContext(FavoritesContext);
	const note = getFavNote(noteId) ?? {}
	const routeNavigator = useRouteNavigator();

	if (!note.id) return (
		<ModalPage>ошибка</ModalPage>
	)
		
	return (
		<Panel
			id="favViewNote"
			nav={nav}
		>
			<PanelHeader separator={false} before={<PanelHeaderBack onClick={() => routeNavigator.replace('/favorites/')} />}>
			<PanelHeaderContent
				status="Сохраненная записка"
				before={note.cat === "zdravie" ? <CrossRed width={36} height={36} style={{borderRadius:"18px", verticalAlign:"middle"}} /> : <CrossBlack width={36} height={36} style={{borderRadius:"18px", verticalAlign:"middle"}} />}
			>
          
			{note.cat === "zdravie" ?
				<ZdravieTitle style={{ maxHeight: "1em", height:"1em", width:"auto", margin: "0 0 -0.2em -0.5em" }}/> :
				<UpokoenieTitle style={{ maxHeight: "1em", height:"1em", width:"auto", margin: "0 0 -0.2em 0" }}/>

			}

          </PanelHeaderContent>
		  </PanelHeader>
			
				<Spacing/>
				<CardGrid size="l">
				<ContentCard
					mode="outline"
					subtitle="Название"
					text={note.title}
          		 />	
				{note.description && note.description.length>0 &&
				<ContentCard
					mode="outline"
					subtitle="Описание"
					text={note.description}
          		 />		
				}
				</CardGrid>
			<Group>
			
			
			<ol className="noteNames">
			{note.items?.map((item, index) => {
					return (
						<li className={note.cat} key={index}><i>{item}</i></li>
					);
			})}
			</ol>
			<FormLayout>
				
				<FormItem>
					<ButtonGroup mode="vertical" stretched>
					<Button
						id="editFavNote"
						stretched
						size="l"
						mode="outline"
						onClick={() => go(`/${FAVORITE_VIEW_PANELS.EDIT}/${note.id}`)}
					>
						Редактировать
					</Button>
					<Button
						id="createNote"
						stretched
						size="l"
						mode="primary"
						appearance="positive"
						onClick={() => go(`/${FAVORITE_VIEW_PANELS.VIEW}/${note.id}/addnote`)}
					>
						Подать записку
					</Button>
					</ButtonGroup>
				</FormItem>
			</FormLayout>
			
			</Group>
		</Panel>
	);
};