import React, {useState, useCallback} from "react";

import {
	Group,
	Panel,
	Placeholder,
	Button,
	PullToRefresh,
	Spacing,
	ConfigProvider,
	AdaptivityProvider,
} from "@vkontakte/vkui";
import { Icon56CancelCircleOutline } from "@vkontakte/icons";

const AppError = () => {
	const [fetching, setFetching] = useState(false);
	const [loading, setLoading] = useState(false);
	const onRefresh = useCallback(() => {
    setFetching(true);

    setTimeout(() => {
      setFetching(false);
      window.location.reload();
    }, 1000);
  }, []);

  	const reload = () => {
		setLoading(true);
		window.location.reload()
	}

	return (
		<ConfigProvider isWebView>
			<AdaptivityProvider>
				<Panel>
					<Group>
						<PullToRefresh onRefresh={onRefresh} isFetching={fetching}>
							<Spacing/>
							<Placeholder
								stretched
								icon={<Icon56CancelCircleOutline color="var(--vkui--color_icon_negative)" />}
								header="Ошибка"
								action={<Button size="l" loading={loading} onClick={reload}>Обновить</Button>}
							>
								<p>Невозможно запустить приложение</p>
								<p>Попробуйте обновить страницу</p>
							</Placeholder>
						</PullToRefresh>
					</Group>
				</Panel>
			</AdaptivityProvider>
		</ConfigProvider>
	);
};

export default AppError;
