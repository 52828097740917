import React, { useState, useContext, useEffect  } from "react";
import { DataContext } from "../context/DataContext";
import { FavoritesContext } from "../context/FavoritesContext";
import {
	ModalPage,
	FormLayout,
	FormItem,
	Button,
	ButtonGroup,
	RadioGroup,
	Radio,
	ModalPageHeader,
	Div,
	Textarea,
	PanelHeaderBack,
} from "@vkontakte/vkui";
import { NavProp, Zapiska } from "../types";
import { NotesAPI } from "../api/notes";
import { CartContext } from "../context/ShoppingCartContext";
import {
	useParams,
	useRouteNavigator,
} from "@vkontakte/vk-mini-apps-router";
import { CART_VIEW_PANELS } from "../routes";
import { loadingSpinner } from "../components/spinners";
//import crossRed from "../img/cross-red.svg";
//import crossBlack from "../img/cross-black.svg";
import { CrossRed, CrossBlack } from "../components/images";

import { useNotification } from '../context/NotificationContext';

export const FavVariationModal = ({ nav }: NavProp) => {
		const { getProduct } = useContext(DataContext);
		const { getFavNote } = useContext(FavoritesContext);
		const { notify, apiErrorNotify } = useNotification();
		const routeNavigator = useRouteNavigator();
		const { noteId, pid } : any = useParams({ panel: nav }) ?? {};
		const favNote = getFavNote(noteId);
		const [variation, setVariation] = useState("");
		const [disable, setDisable] = useState(true);
		const [custom, setCustom] = useState(false);
		//const [modalBefore, setModalBefore] = useState(<Image size={22} src={favNote.cat === "zdravie" ? crossRed : crossBlack} />);
		const [modalBefore, setModalBefore] = useState(favNote.cat === "zdravie" ? <CrossRed width={22} height={22} style={{marginLeft:"8px", borderRadius:"4px", verticalAlign:"middle"}} /> : <CrossBlack width={22} height={22} style={{marginLeft:"8px", borderRadius:"4px", verticalAlign:"middle"}} />);
		const product = getProduct(favNote.cat,pid);
		const { addToCart } = useContext(CartContext);

		useEffect(() => {
			variation.length>9?setDisable(false):setDisable(true);
		}, [variation]);

		useEffect(() => {
			if (custom) {
				setModalBefore(<PanelHeaderBack onClick={() => setCustom(false)} />);
			} else {				
				setModalBefore(favNote.cat === "zdravie" ? <CrossRed width={22} height={22} style={{marginLeft:"8px", borderRadius:"4px", verticalAlign:"middle"}} /> : <CrossBlack width={22} height={22} style={{marginLeft:"8px", borderRadius:"4px", verticalAlign:"middle"}} />);
				//setModalBefore(<Image size={22} src={favNote.cat === "zdravie" ? crossRed : crossBlack} />);
			}
		}, [custom]);

		async function save() {
			await routeNavigator.showPopout(loadingSpinner)
	
			let note = {} as Zapiska;
	
			note.cat = favNote.cat;
			note.pid = product.id;
			note.title = product.title;
			note.items = favNote.items;
			note.sub = variation;

			await NotesAPI.create(note)
			.then((result) => {
				note.id = result.data.i;
				note.hash = result.data.h;
				note.amount = result.data.a;
				addToCart(note);
				notify('Записка успешно добавлена','success');
				routeNavigator.replace(`/${CART_VIEW_PANELS.EDIT}/${note.id}`);
			}).catch((error) => {
				apiErrorNotify(error);
			})
			};


		const closeModal = () => {
			setVariation("");
			routeNavigator.hidePopout();
		}

		const textareaChange = (e:any) => {
			setVariation(e.target.value)
		}
		
		return (
			<ModalPage
				id="variation"
				header={
					<ModalPageHeader
						before={modalBefore}
						separator={true}
					>{product.title}</ModalPageHeader>
				}
				dynamicContentHeight
				onClose={closeModal}
			>
				
				{custom && 
				
				<FormLayout>
					<FormItem>
						<Textarea rows={1} minLength={10} maxLength={200} placeholder="Кому" autoFocus onInput={textareaChange} />
					</FormItem>

				</FormLayout>
				}
				{!custom && 
				<RadioGroup>
				{product.variations?.map((val, i) => (
					<Radio key={i} name="variation" value={val.title} onChange={()=>setVariation(val.title)}>{val.title}</Radio>
				))}
					<Radio key="custom" name="variation" onChange={()=>{setVariation("");setCustom(true);}}>Свой вариант</Radio>
				</RadioGroup>
				}
				<Div>
				<ButtonGroup mode="horizontal" stretched>
					<Button mode="primary" stretched disabled={disable} onClick={save}>Выбрать</Button>
					<Button mode="tertiary" appearance="negative" stretched onClick={closeModal}>Отмена</Button>
				</ButtonGroup>
				</Div>
			</ModalPage>
		);
};