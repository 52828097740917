import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Snackbar } from '@vkontakte/vkui';
import { Icon28CheckCircleOutline, Icon28ErrorCircleOutline, Icon28InfoCircleOutline } from '@vkontakte/icons';
import '@vkontakte/vkui/dist/vkui.css';
import { axiosErrors } from '../constants';

export type NotificationType = 'success' | 'error' | 'info';

export interface Notification {
  message: string;
  type: NotificationType;
}

interface NotificationContextProps {
  notify: (message: string, type: NotificationType) => void;
  apiErrorNotify: (error:any) => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [notification, setNotification] = useState<Notification | null>(null);

  const notify = (message: string, type: NotificationType) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000); // Уведомление будет отображаться 3 секунды
  };

  const apiErrorNotify = (error: any) => {
	let type:NotificationType = 'error';
	let message:string;
	if (error.response && error.response.message!=="") {
		message = error.response.message
	} else {
		message = axiosErrors[error.code as keyof typeof axiosErrors]||'';
		if (message==='') {
			message = "Ошибка. Попробуйте позже"
		}
	}
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000); // Уведомление будет отображаться 3 секунды
  };

  return (
    <NotificationContext.Provider value={{ notify, apiErrorNotify }}>
      {children}
      {notification && (
        <Snackbar
          onClose={() => setNotification(null)}
          before={
            <>
              {notification.type === 'success' && <Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
              {notification.type === 'error' && <Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)" />}
              {notification.type === 'info' && <Icon28InfoCircleOutline fill="var(--vkui--color_icon_secondary)" />}
			</>
          }
        >
          {notification.message}
        </Snackbar>
      )}
    </NotificationContext.Provider>
  );
};
