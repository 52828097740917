import React, {useContext} from "react";

import {
	Group,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	Placeholder,
	Button
} from "@vkontakte/vkui";

import {
	useParams,
	useRouteNavigator
} from "@vkontakte/vk-mini-apps-router";
import "./panels.css";
import { NoteEditForm } from "../forms/NoteEditForm";
import { CartContext } from "../context/ShoppingCartContext";
import { NavProp, GoFunctionProp } from "../types";
import { Icon56ErrorTriangleOutline } from "@vkontakte/icons";

type CartEditNoteProps = NavProp & GoFunctionProp;

export const CartEditNote = ({ nav, go }: CartEditNoteProps) => {
	const { noteId }: any = useParams({ panel: nav }) ?? {};
	const { getNote } = useContext(CartContext);
	
	const note = getNote(noteId) ?? null

	const routeNavigator = useRouteNavigator();

	return (
		<Panel id="cartEditNote" nav={nav}>
			<PanelHeader
				fixed
				before={<PanelHeaderBack onClick={() => routeNavigator.back()} />}
			>
				Изменить
			</PanelHeader>
			<Group>
				{note && (
					<NoteEditForm note={note} />
				)}

				{!note && (
					<Placeholder
						icon={<Icon56ErrorTriangleOutline fill="var(--vkui--color_icon_negative)" />}
						header="Записка не найдена"
						action={
							<Button
								size="m"
								onClick={() => routeNavigator.replace('/cart/')}
							>Закрыть</Button>
						}
					/>
				)}
				
			</Group>
		</Panel>
	);
};
