import React from "react";

import {
	Group,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	Div,
} from "@vkontakte/vkui";

import {
	useRouteNavigator,
} from "@vkontakte/vk-mini-apps-router";
import "./panels.css";
import { NavProp } from "../types";

const Info = (props: NavProp) => {
	const routeNavigator = useRouteNavigator();
	return (
		<Panel nav={props.nav}>
			<PanelHeader
				fixed
				before={<PanelHeaderBack onClick={() => routeNavigator.back()} />}
			>
				Про записки
			</PanelHeader>
			<Group>
				<Div>
					<h1>Зачем в храмах пишут записки?</h1>
					<blockquote>
						«Истинно также говорю вам, что если двое из вас согласятся на земле
						просить о всяком деле, то, чего бы ни попросили, будет им от Отца
						Моего Небесного, ибо, где двое или трое собраны во имя Мое, там Я
						посреди них» <em>(Мф.18:19-20)</em>
					</blockquote>
					<p>
						Совместная молитва, по слову Христову, имеет большую силу перед
						Богом. Церковная молитва – это молитва соборная, совершаемая
						священнослужителями и прихожанами вместе.
					</p>
					<p>
						Записки о здравии или упокоении – это просьба верующего человека к
						священнослужителям вознести свою молитву о конкретном человеке во
						время богослужения.
					</p>
					<h3>«О здравии»</h3>
					<p>
						Понятие «здравие» включает не только физическое здоровье человека,
						но и его духовное состояние. Поэтому, подавая записки о здравии, мы
						просим Бога не только о здоровье, но, прежде всего, о прощении
						грехов и исправлении жизни, о мире в душе, а также о благополучии и
						помощи во всяком добром деле.
					</p>
					<p>
						В записках о здравии можно указывать не только имена родных и
						близких, но и имена тех, кто нас обижает или притесняет. Господь,
						видя наше стремление к миру, может смягчить сердце недруга. Но,
						главное, через молитву о врагах мы учимся истинной христианской
						любви, исполняя заповедь Господа нашего Иисуса Христа:
					</p>
					<blockquote>
						<em>
							«Любите врагов ваших, благословляйте проклинающих вас,
							благотворите ненавидящим вас и молитесь за обижающих вас и гонящих
							вас, да будете сынами Отца вашего Небесного» (Мф.5:44-45)
						</em>
					</blockquote>
					<h3>«О упокоении»</h3>
					<p>
						В этой записке мы пишем имена усопших родственников, друзей,
						знакомых и всех, кто нам дорог. Как молимся мы о живых, так должны
						молиться и за усопших. Даже когда прекращается жизнь биологическая,
						человек продолжает жить невидимой для нас духовной жизнью пред очами
						Божиими. Сам Господь говорит нам в Святом Евангелии:
					</p>
					<blockquote>
						<em>
							«Бог же не есть Бог мертвых, но живых, ибо у Него все живы»
							(Лк.20:38)
						</em>
					</blockquote>
					<p>
						Молясь за усопших, мы просим Бога простить грехи этим людям и, тем
						самым, облегчить их загробную участь. Также мы верим, что и усопшие
						молятся о нас Богу.
					</p>
					<h3>За кого можно и нельзя подавать записки?</h3>
					<p>Можно подавать записки за крещеных православных христиан.</p>
					<p>
						Записки в храме не подают за тех, кто не является членом
						Православной Церкви: за некрещеных, инославных (католиков,
						протестантов), иноверцев, за самоубийц (если нет архиерейского
						благословения на их отпевание и церковное поминовение), за
						убежденных атеистов и богоборцев, даже если они были крещены. За
						таких людей можно молиться келейно (домашней молитвой).
					</p>
					<h3>Как писать имена в записке?</h3>
					<p>
						Желательно писать имена в родительном падеже (
						<em>Марии, Александра, Елены</em>) и в соответствии с церковной
						традицией, т.е. именами святых, в честь которых крещены люди –{" "}
						<em>Ксении</em> (а не <em>Оксаны</em>), <em>Иоанна</em> (а не{" "}
						<em>Ивана</em>) и т.д.
					</p>
					<p>
						Имена священнослужителей по традиции пишут первыми в списке, перед
						именами указывают сан, полностью или в сокращении, например:{" "}
						<em>
							«архиепископа» (архиеп.), «протоиерея» (прот.), «иерея» (иер.)
						</em>
						.
					</p>
					<p>
						К имени поминаемого допускается добавление слов:{" "}
						<em>
							«младенца» («мл.», до 7 лет), «отрока» («отр.», с 7 до 14 лет),
							«болящего» (бол.), «воина».
						</em>
					</p>
					<p>
						В заупокойных записках перед именем усопшего в течение 40 дней после
						кончины обычно добавляют слово: «<em>новопреставленного</em>» (
						<em>новопр</em>., <em>нов</em>.).
					</p>
					<h3>Какие записки бывают?</h3>
					<p>
						<strong>Проскомидия</strong> – первая, подготовительная, часть
						Литургии, во время которой приготовляют хлеб и вино для Таинства
						Евхаристии.
					</p>
					<p>
						За каждое имя, указанное в записке, из просфор (специальных
						богослужебных хлебов) вынимаются частицы, которые в конце Литургии
						после причащения мирян опускаются в Кровь Христову, с молитвой о
						прощении грехов поминаемых:{" "}
						<em>
							«Отмый, Господи, грехи поминавшихся зде Кровию Твоею честною,
							молитвами святых Твоих»
						</em>
						.
					</p>
					<p>
						<strong>Ектения</strong> (в просторечии – «Обедня», «Заказная»).
						Записка подается для поминовения как на Проскомидии, так и во
						всеуслышание на Сугубой ектении, читаемой сразу после Евангелия.
					</p>
					<p>
						<strong>Сорокоуст</strong> – это поминовение, которое совершается
						Церковью в течение 40 Литургий. В течение этого срока на Проскомидии
						происходит вынимание частиц из просфоры за каждое имя.
					</p>
					<p>
						<strong>Поминовение на полгода, год.</strong> Священнослужители
						молятся об указанных в записках людях в течение полугода или года,
						соответственно.
					</p>
					<p>
						<strong>Молебен</strong> – это краткое частное богослужение, при
						котором верующие просят Господа, Богородицу или святых о ниспослании
						милости или благодарят Бога за получение благодеяний. Зачастую
						совершается по окончании Литургии. Записки подаются о здравии.
					</p>
					<p>
						<strong>Панихида</strong> – это особое богослужение об усопших.
						Панихида совершается как до погребения усопшего христианина, так и
						после, обычно в 3‑й, 9‑й, 40‑й день по смерти, в дни его рождения,
						тезоименитства, в годовщину смерти. Возможно совершение панихиды и в
						другие дни.
					</p>
				</Div>
			</Group>
		</Panel>
	);
};

export default Info;
