import axios from 'axios';
import bridge from "@vkontakte/vk-bridge";

const api = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	timeout: 5000,
	headers: {
		post: {
		  'Content-Type': 'application/json',
		}
	}
  });


  const getLaunchParams = async() => {
	try {
		const launchData = await bridge.send('VKWebAppGetLaunchParams')
		  if (launchData.vk_app_id) {
			return launchData;
		  } else {
			return null;
		  }
	} catch(e) {
		return null;
	}
  };

  const getToken = async (s: string) => {
	try {
		const data = await bridge.send('VKWebAppCreateHash', {payload: s})
		if (data.ts) {
			return btoa(JSON.stringify(data));
		} else {
			return "";
		}
  	} catch(e) {
		return "";
  	}
  };

  api.interceptors.request.use(	
	async function (config) {
		if (!config?.url?.includes('init')) {
				let token = sessionStorage.getItem("token")||"";
				let tokenData: any;
				if (token != "") {
					tokenData = JSON.parse(atob(token));
				}
				
				const now = Math.floor(Date.now()/1000);
				//console.log("Date.now() : ", now);
				//console.log("tokenData?.ts : ", tokenData?.ts);
				//console.log("Date.now()-tokenData?.ts : ",now-tokenData?.ts);
				if (token ==="" || (tokenData?.payload && tokenData?.ts && (now-tokenData?.ts > 900))) {
					const params = await getLaunchParams();
					if (params) {
						let tStr = params.vk_app_id+"-"+params.vk_user_id;
						token = await getToken(tStr);
						sessionStorage.setItem("token", token);
					}
				}
				config.headers['X-Auth'] = sessionStorage.getItem("token")||"";
		}
	  return config;
	},
	function (error) {
	  return Promise.reject(error);
	}
  );

  api.interceptors.response.use(
	function (response) {
	  return response;
	},
	function (error) {
	  return Promise.reject(error);
	}
  );

export default api;