import React, { useState, useEffect, useContext } from "react";
import {
	FormLayout,
	FormItem,
	Input,
	ButtonGroup,
	Button,
	Text,
	IconButton,
	Textarea,
	Checkbox,
	Footnote,
} from "@vkontakte/vkui";
import { Icon12Add } from "@vkontakte/icons";
import { CartContext } from "../context/ShoppingCartContext";
import { Zapiska, Product } from "../types";
import { CART_VIEW_PANELS } from "../routes";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { Icon16Clear } from "@vkontakte/icons";
import { NotesAPI } from "../api/notes";
import { loadingSpinner } from "../components/spinners";
import { useNotification } from '../context/NotificationContext';

type ZapiskaProps = {
	note: Zapiska;
	product: Product;
};

export const ZapiskaForm = ({note, product}: ZapiskaProps) => {
	const { notify, apiErrorNotify } = useNotification();
	const commentMaxLen = 200;
	const nameMaxLen = 50;

	const [values, setValues] = useState(Array(5).fill(""));
	const [names, setNames] = useState<string[]>([]);

	const [isReadOnly, setIsReadOnly] = useState(true);
	const [withComment, setWithComment] = useState(false);

	const { addToCart } = useContext(CartContext);
	const routeNavigator = useRouteNavigator();

	const addFields = () => {
		setValues(values.concat(Array(5).fill("")));
	};

	function timeout(delay: number) {
		return new Promise((res) => setTimeout(res, delay));
	}

	const handleChange =
		(index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
			let newArr = [...values];
			newArr[index] = e.target.value;
			setValues(newArr);
		};

	const handleDelete = (index: number) => {
		setValues(values.filter((value, i) => i !== index));
	};

	useEffect(() => {
		setNames(values.filter(function (el) {return el !== "";}))
	}, [values]);

	useEffect(() => {
		if (names.length===0) {
			setIsReadOnly(true)
		} else {
			setIsReadOnly(false)
		}
	}, [names.length]);

	async function save() {
		note.items = names;
		if (comment.length>0) {
			note.comment = comment;
		}

		await routeNavigator.showPopout(loadingSpinner)
		await NotesAPI.create(note)
		.then((result) => {
			let newNote = note;

			newNote.id = result.data.i;
			newNote.hash = result.data.h;
			newNote.amount = result.data.a;
			
			addToCart(newNote);
			notify('Записка успешно сохранена','success')
			timeout(1000);
			routeNavigator.push(`/${CART_VIEW_PANELS.HOME}/`);
		}).catch((error) => {
			apiErrorNotify(error);
		})
	};

	const withCommentChange = (e:any) => {
		setWithComment(e.target.checked);
		if (!e.target.checked) {
			setComment("");
		}
	}

	const [comment, setComment] = useState("")
	const commentChange = (e:any) => {
		setComment(e.target.value)
	}

	return (
		<FormLayout id={product.id}>
			{values.map((val:string, index) => {
				return (
					<FormItem key={index} style={{ flexGrow: 1, flexShrink: 1 }}>
						<Input
							key={index}
							maxLength={nameMaxLen}
							value={val}
							before={<Text>{index + 1}.</Text>}
							onChange={handleChange(index)}
							after={val.length>0 && 
								<IconButton
									hoverMode="opacity"
									aria-label="Удалить"
									title="Удалить"
									onClick={() => handleDelete(index)}
								>
									<Icon16Clear />
								</IconButton>
							}
						/>
					</FormItem>
				);
			})}
			<ButtonGroup
			mode="horizontal"
			align="center"
			gap="none"
			style={{ width: "100%" }}
			>
				<Button
								appearance="accent"
								size="m"
								align="center"
								mode="tertiary"
								disabled={isReadOnly}
								before={<Icon12Add />}
								onClick={addFields}
							>
								добавить еще имена
							</Button>
				</ButtonGroup>
				<FormItem bottom={withComment && <Footnote style={{ textAlign: "right", marginTop:-28, position:"relative", fontSize:"0.8em", right:7, zIndex:9999 }}>{comment.length} / {commentMaxLen}</Footnote>}>
					<Checkbox hoverMode="opacity" hasActive={false} onChange={withCommentChange}>Добавить комментарий</Checkbox>
					{withComment && <Textarea style={{paddingBottom:8}} name="comment" onInput={commentChange} autoFocus placeholder="Комментарий к записке" maxLength={commentMaxLen} />}
				</FormItem>
				<FormItem>
					<ButtonGroup
						mode="horizontal"
						align="center"
						gap="none"
						style={{ width: "100%" }}
					>
						<ButtonGroup
							mode="vertical"
							align="center"
							gap="s"
							style={{ minWidth: 328 }}
						>
							<Button
								id="addToCart"
								stretched
								size="l"
								mode="primary"
								appearance="positive"
								disabled={isReadOnly}
								onClick={save}
							>
								Добавить в заявку
							</Button>
							
							<Button
								id="cancel"
								stretched
								size="m"
								mode="tertiary"
								appearance="negative"
								onClick={() =>  routeNavigator.replace(`/`)}
							>
								Отмена
							</Button>
						</ButtonGroup>
					</ButtonGroup>
				</FormItem>
		</FormLayout>
	);
};