import React, {useContext} from "react";

import {
	Group,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	Placeholder,
	Button
} from "@vkontakte/vkui";

import {
	useParams,
	useRouteNavigator
} from "@vkontakte/vk-mini-apps-router";
import "./panels.css";
import { FavoriteEditForm } from "../forms/FavoriteEditForm";
import { FavoritesContext } from "../context/FavoritesContext";
import { NavProp, GoFunctionProp } from "../types";
import { Icon56ErrorTriangleOutline } from "@vkontakte/icons";

type FavoritesEditProps = NavProp & GoFunctionProp;

export const FavoritesEdit = ({ nav, go }: FavoritesEditProps) => {
	const { noteId }: any = useParams({ panel: nav }) ?? {};
	const { getFavNote } = useContext(FavoritesContext);
	const note = getFavNote(noteId) ?? null
	const routeNavigator = useRouteNavigator();

	return (
		<Panel id="favEditNote" nav={nav}>
			<PanelHeader
				fixed
				before={<PanelHeaderBack onClick={() => routeNavigator.back()} />}
			>
				Изменить
			</PanelHeader>
			<Group>
				{note && (
					<FavoriteEditForm note={note} />
				)}
				{!note && (
					<Placeholder
						icon={<Icon56ErrorTriangleOutline fill="var(--vkui--color_icon_negative)" />}
						header="Записка не найдена"
						action={
							<Button
								size="m"
								onClick={() => routeNavigator.replace('/favorites/')}
							>Закрыть</Button>
						}
					/>
				)}
			</Group>
		</Panel>
	);
};