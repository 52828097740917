import React, { useState, useContext, useEffect } from "react";
import {
	FormLayout,
	FormItem,
	Input,
	ButtonGroup,
	Button,
	Text,
	IconButton,
	Checkbox,
	Textarea,
	Footnote,
} from "@vkontakte/vkui";
import { Icon12Add } from "@vkontakte/icons";
import { CartContext } from "../context/ShoppingCartContext";
import { Zapiska, ZapiskaRequest } from "../types";
import { CART_VIEW_PANELS } from "../routes";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { Icon16Clear } from "@vkontakte/icons";
import { NotesAPI } from "../api/notes";
import { loadingSpinner } from "../components/spinners";
import { nameMaxLen, commentMaxLen } from "../constants";
import { useNotification } from '../context/NotificationContext';

type NoteEditFormProps = {
	note:Zapiska;
};

export const NoteEditForm = (props: NoteEditFormProps) => {
	const note = props.note;
	//const currentNote = useRef(props.note);
	//const note = currentNote.current;
	const { notify, apiErrorNotify } = useNotification();
	const [values, setValues] = useState<string[]>(note.items || []);
	const [withComment, setWithComment] = useState(note.comment?true:false);
	const [comment, setComment] = useState(note.comment?note.comment:"");

	const { updateNoteInCart } = useContext(CartContext);
	const routeNavigator = useRouteNavigator();

	const addFields = () => {
		setValues(values.concat(Array(5).fill("")));
	};

	function timeout(delay: number) {
		return new Promise((res) => setTimeout(res, delay));
	}

	const handleChange =
		(index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
			let newArr = [...values];
			newArr[index] = e.target.value;
			setValues(newArr);
		};

	const handleDelete = (index: number) => {
		setValues(values.filter((value, i) => i !== index));
	};

	function isArraysEqual(firstArray:any, secondArray:any) {
		return firstArray.toString() === secondArray.toString();
	  }

	const [toUpdate, setToUpdate] = useState(false);
	useEffect(() => {
		if (!isArraysEqual(note.items, values.filter(function (el) {return el !== ""}))) {
			setToUpdate(true);
		}

		if (note.comment !== comment) {
			setToUpdate(true);
		}
	}, [values, comment, note.comment, note.items]);

	async function update(note:Zapiska) {
		if (toUpdate) {
			let reqNote = {id:note.id} as ZapiskaRequest;
			let newNames = values.filter(function (el) {return el !== ""});
			if (!isArraysEqual(note.items, newNames)) {
				reqNote.items = newNames;
			}
			note.items = newNames;

			if (note.comment !== comment) {
				reqNote.cmnt = comment;
			}
			note.comment = comment;

			await routeNavigator.showPopout(loadingSpinner)
			await NotesAPI.update(reqNote)
			.then((result) => {
				if (result.data?.hash!=="") {
					note.hash = result.data.hash;
				}
				updateNoteInCart(note);
				notify('Записка успешно сохранена','success')
				timeout(1000);
				routeNavigator.push(`/${CART_VIEW_PANELS.HOME}/`);
			}).catch((error) => {
				apiErrorNotify(error);
			}).finally(() => {
				return routeNavigator.hidePopout()
			}
			);
		} else {
			routeNavigator.push(`/${CART_VIEW_PANELS.HOME}/`);
		}
	};

	const [isReadOnly, setIsReadOnly] = useState(false);

	const withCommentChange = (e:any) => {
		setWithComment(e.target.checked);
		if (!e.target.checked) {
			setComment("");
		}
	}

	const commentChange = (e:any) => {
		setComment(e.target.value)
	}

	return (
		<FormLayout id={note.id}>
			{values.map((val:string, index) => {
				return (
					<FormItem key={index} style={{ flexGrow: 1, flexShrink: 1 }}>
						<Input
							key={index}
							value={val}
							maxLength={nameMaxLen}
							before={<Text>{index + 1}.</Text>}
							disabled={isReadOnly}
							onChange={handleChange(index)}
							after={val.length>0 && 
								<IconButton
									hoverMode="opacity"
									aria-label="Удалить"
									onClick={() => handleDelete(index)}
								>
									<Icon16Clear />
								</IconButton>
							}
						/>
					</FormItem>
				);
			})}
			<ButtonGroup
				mode="horizontal"
				align="center"
				gap="none"
				style={{ width: "100%" }}
			>
				<Button
					appearance="accent"
					size="m"
					align="center"
					mode="tertiary"
					disabled={isReadOnly}
					before={<Icon12Add />}
					onClick={addFields}
				>
					добавить еще имена
				</Button>
			</ButtonGroup>
			<FormItem bottom={withComment && <Footnote style={{ textAlign: "right", marginTop:-28, position:"relative", fontSize:"0.8em", right:7, zIndex:9999 }}>{comment.length} / {commentMaxLen}</Footnote>}>
				<Checkbox hoverMode="opacity" hasActive={false} defaultChecked={withComment?true:false} onChange={withCommentChange}>Добавить комментарий</Checkbox>
				{withComment && <Textarea style={{paddingBottom:8}} name="comment" onInput={commentChange} autoFocus placeholder="Комментарий к записке" defaultValue={note.comment?note.comment:""} maxLength={commentMaxLen} />}
			</FormItem>
			<FormItem>
				<ButtonGroup
					mode="horizontal"
					align="center"
					gap="none"
					style={{ width: "100%" }}
				>
					<ButtonGroup
						mode="vertical"
						align="center"
						gap="s"
						style={{ minWidth: 328 }}
					>
						<Button
							id="updateNote"
							stretched
							size="l"
							mode="primary"
							/* appearance="positive" */
							disabled={isReadOnly}
							onClick={() => update(note)}
						>
							Сохранить
						</Button>
						<Button
							id="cancel"
							stretched
							size="m"
							mode="tertiary"
							appearance="negative"
							disabled={isReadOnly}
							onClick={() => routeNavigator.back()}
						>
							Отмена
						</Button>
					</ButtonGroup>
				</ButtonGroup>
			</FormItem>
		</FormLayout>
	);
};