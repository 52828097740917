import React, { useContext, useRef } from "react";

import {
	Button,
	Div,
	ModalPage,
	//usePlatform,
	//Platform,
	Group,
	Spacing,
	CardGrid,
	ContentCard,
	Title,
} from "@vkontakte/vkui";

import {
	useParams,
	useRouteNavigator,
} from "@vkontakte/vk-mini-apps-router";
import { NavProp, GoFunctionProp } from "../types";
import zdravie from "../img/zdravie.svg";
import upokoenie from "../img/upokoenie.svg";
import { CartContext } from "../context/ShoppingCartContext";
import { CART_VIEW_PANELS } from "../routes";
import { UpokoenieTitle, ZdravieTitle } from "../components/images";

type  CartViewNoteProps = NavProp  & GoFunctionProp;

export const CartViewNoteModal = ({ nav, go }:  CartViewNoteProps) => {
	const routeNavigator = useRouteNavigator();
	const { noteId }: any = useParams({ panel: nav }) ?? null;
	/* if (!noteId) {
		routeNavigator.hideModal();
	} */

	const { getNote } = useContext(CartContext);
	//const note = getNote(noteId) ?? {}
	const note = useRef(getNote(noteId) ?? {})
	return (
		<ModalPage
			id={nav}
			nav={nav}
			dynamicContentHeight
			onClose={()=>routeNavigator.hideModal()}
		>
			<Group>
			<Div>
				<div style={{ textAlign: "center" }}>
					{note.current.cat === "zdravie" ? 
						<ZdravieTitle height="32px" width="auto" />:
						<UpokoenieTitle height="32px" width="auto" />
					}
					{/* <img
						key={note.current.cat}
						style={{ height: "32px", width: "auto", margin: "auto" }}
						loading="lazy"
						alt=""
						src={note.current.cat === "zdravie" ? zdravie : upokoenie}
					/> */}
						<Title level="2">{note.current.title}</Title>
						{note.current.sub?<Title level="3" weight="3">{note.current.sub}</Title>:""}
				</div>
				<ol className="noteNames">
					{note.current.items?.map((item, index) => {
							return (
								<li className={note.current.cat} key={index}><i>{item}</i></li>
							);
					})}
				</ol>
				{note.current.comment && note.current.comment.length>0 &&
					<Group mode="plain">
						<Spacing/>
						<CardGrid
							size="l"
						>
						<ContentCard
							subtitle="Комментарий"
							text={note.current.comment}
						/>		
						</CardGrid>
					</Group>
				}

					<Button
						//id="editNote"
						stretched
						size="l"
						mode="primary"
						onClick={() => go(`/${CART_VIEW_PANELS.EDIT}/${note.current.id}`)}
					>
						Редактировать
					</Button>
				</Div>
			
			</Group>
		</ModalPage>
	);
};