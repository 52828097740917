import React, { useContext, useEffect, useRef, useState } from "react";
import {
	Placeholder,
	Panel,
	PanelHeader,
	Group,
	RichCell,
	IconButton,
	SimpleCell,
	Counter,
	ActionSheet,
	ActionSheetItem,
	ActionSheetDefaultIosCloseItem,
	usePlatform,
	Platform,
	CardGrid,
	Card,
} from "@vkontakte/vkui";

import {
	useRouteNavigator,
} from "@vkontakte/vk-mini-apps-router";

import { loadingSpinner } from "../components/spinners";
import {
	NavProp,
	GoFunctionProp,
} from "../types";

import { FavoritesContext } from "../context/FavoritesContext";
//import crossRed from "../img/cross-red.svg";
//import crossBlack from "../img/cross-black.svg";

//import {ReactComponent as CrossRed} from '../img/cross-red.svg';
import { CrossRed, CrossBlack } from "../components/images";
//import {ReactComponent as CrossBlack} from '../img/cross-black.svg';

import { Icon16MoreVertical, Icon16ArticleBoxOutline, Icon16DeleteOutline, Icon16User, Icon16ViewOutline, Icon56FavoriteOutline } from "@vkontakte/icons";
import { UUID } from "crypto";
import { FavoritesAPI } from "../api/favorites";
import { trebaCats } from "../constants";
import { FAVORITE_VIEW_PANELS } from "../routes";
import { useNotification } from '../context/NotificationContext';

type FavoritesPanelProps = NavProp & GoFunctionProp;

export const Favorites = ({ nav, go }: FavoritesPanelProps) => {
	const { notify, apiErrorNotify } = useNotification();
	const platform = usePlatform();
	const isVKCOM = platform === Platform.VKCOM;
	const [disabledID, setDisabledID] = useState("");
	const eRef = useRef<(HTMLElement | null)[]>([]);
	
	const routeNavigator = useRouteNavigator();
	
	const onClose = () => routeNavigator.hidePopout();
	
	

	const { favItems, removeFromFav, setFavHasNew } = useContext(FavoritesContext);

	useEffect(() => {
		setFavHasNew(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
  	}, []); 

	async function remove(id:UUID) {
		await routeNavigator.showPopout(loadingSpinner)
		await FavoritesAPI.delete(id)
		.then((result) => {
			removeFromFav(id);
			notify('Записка успешно удалена из избранного','success')
		}).catch((error) => {
			apiErrorNotify(error)
		}).finally(() => {
			return routeNavigator.hidePopout()
		}
		);
	};
	const removeFavItem = (id:string) => {
		setDisabledID(id);
		remove(id as UUID);
	}

	const openNote = (id:string) => {
		if (isVKCOM) {
			routeNavigator.push(`/favorites/mv/${id}`)
		} else {
			routeNavigator.push(`/favorites/view/${id}`)
		}
	}
	const handleIconButtonClick = (event:any, index:number) => {
		event.stopPropagation();
		FavItemPopout(eRef.current[index] as HTMLElement)
	  };

	const FavItemPopout = (r:HTMLElement) => {
		routeNavigator.showPopout(
		  <ActionSheet mode="menu" onClose={onClose} toggleRef={r} iosCloseItem={<ActionSheetDefaultIosCloseItem/>}>
			<ActionSheetItem onClick={() => openNote(r.id)} before={<Icon16ViewOutline />}>Открыть</ActionSheetItem>
			<ActionSheetItem onClick={() => go(`/${FAVORITE_VIEW_PANELS.HOME}/addnote/${r.id}`)}  before={<Icon16ArticleBoxOutline />}>Подать записку</ActionSheetItem>
			<ActionSheetItem before={<Icon16DeleteOutline />} onClick={() => removeFavItem(r.id)} autoClose mode="destructive">Удалить</ActionSheetItem>
		  </ActionSheet>
		);
	  };
	
	if (favItems.length === 0) {
		return (
			<Panel id="favorites" nav={nav}>
				<PanelHeader fixed>Избранное</PanelHeader>
					<Placeholder
						icon={<Icon56FavoriteOutline />}
						header="Ничего не найдено"
					>
						<p>Добавить записку в избранное можно из заявки (раздел "Мои записки") или из истории поданных записок.</p>
					</Placeholder>
			</Panel>
		)
	}
	return (
		<Panel id="favorites" nav={nav}>
			<PanelHeader fixed>Избранное</PanelHeader>
			<Group>
				
				<CardGrid
				 	size="l"
					 style={isVKCOM?{width:"70%", margin: "auto"}:{}}
				>
				{favItems.map((item, index) => {
					return (
						<Card
							key={item.id}
							mode={disabledID===item.id?"tint":"shadow"}
							style={disabledID===item.id?{opacity:.5}:{}}
						>
						<RichCell
							style={{paddingLeft:8, paddingRight: 2}}
							//hasHover={false}
							//hasActive={false}
							before={item.cat === "zdravie" ? <CrossRed width={48} height={48} style={{borderRadius:"4px"}} /> : <CrossBlack width={48} height={48} style={{borderRadius:"4px"}} />}
							text={item.title}
							caption={item.description?item.description:trebaCats[item.cat as keyof typeof trebaCats]}
							onClick={() => openNote(item.id as string)}
							after={
								<Group mode="plain">
									<SimpleCell
										key={item.id}
										disabled={true}
										indicator={
											<Counter size="s">
												<Icon16User /> {item.items?.length as number}
											</Counter>
										}
										after={
											<IconButton
												id={item.id}
												aria-label="Действия"
												getRootRef={ref => {
													eRef.current[index] = ref
												  }}
												  onClick={(event)=>handleIconButtonClick(event, index)}
												//onClick={() => FavItemPopout(eRef.current[index] as HTMLElement)}
											>
												<Icon16MoreVertical />
											</IconButton>
										}
									></SimpleCell>
								</Group>
							}
						/>
						</Card>
					);
				})}
				</CardGrid>
			</Group>
		</Panel>
	);
};