import React, { useState, useEffect, useContext, useRef } from "react";
import {
	FixedLayout,
	Placeholder,
	Spacing,
	Panel,
	PanelHeader,
	Group,
	Button,
	RichCell,
	IconButton,
	SimpleCell,
	Counter,
	Caption,
	ActionSheet,
	ActionSheetItem,
	ActionSheetDefaultIosCloseItem,
	Spinner,
	usePlatform,
	Platform,
	Card,
	CardGrid,
	Div
} from "@vkontakte/vkui";

import {
	useRouteNavigator,
} from "@vkontakte/vk-mini-apps-router";
import { OrderAPI } from "../api/orders";
import { loadingSpinner } from "../components/spinners";
import { NavProp, GoFunctionProp, Zapiska, Favorite, NewOrder } from "../types";

import { CartContext } from "../context/ShoppingCartContext";
import { FavoritesContext } from "../context/FavoritesContext";
import { useNotification } from '../context/NotificationContext';
//import crossRed from "../img/cross-red.svg";
//import crossBlack from "../img/cross-black.svg";

//import {ReactComponent as CrossRed} from '../img/cross-red.svg';
//import {ReactComponent as CrossBlack} from '../img/cross-black.svg';
import { CrossRed, CrossBlack } from "../components/images";
import bridge from "@vkontakte/vk-bridge";

import { Icon16DeleteOutline, Icon16Favorite, Icon16FavoriteOutline, Icon16User, Icon16ViewOutline } from "@vkontakte/icons";
import { Icon32Cards2Outline, Icon16MoreVertical } from "@vkontakte/icons";
import { UUID } from "crypto";
import { NotesAPI } from "../api/notes";
import { trebaCats } from '../constants';
type CartPanelProps = NavProp & GoFunctionProp;

export const Cart = ({ nav, go }: CartPanelProps) => {
	const { notify, apiErrorNotify } = useNotification();
	const platform = usePlatform();
	const isVKCOM = platform === Platform.VKCOM;
	const eRef = useRef<(HTMLElement | null)[]>([]);
	const [disabledID, setDisabledID] = useState("");
	//const isFirstPage = useFirstPageCheck(); // ??
	const routeNavigator = useRouteNavigator();
	const { cartItems, removeFromCart, getNote } = useContext(CartContext);
	const { addToFav, hasFavByHash, removeFavByHash, favLoading } = useContext(FavoritesContext);

	async function remove(id:UUID) {
		await routeNavigator.showPopout(loadingSpinner)
		await NotesAPI.delete(id)
		.then((result) => {
			removeFromCart(id);
		}).catch((error:any) => {
			apiErrorNotify(error)
		}).finally(() => {
			return routeNavigator.hidePopout()
		}
		);
	};
	
	async function changeFavorite(id:UUID, hash:string) {
		if (!hasFavByHash(hash)) {
			let cartNote = getNote(id as string)
			let favData:Favorite = {
				id:id as UUID,
				cat:cartNote.cat as string,
				title:cartNote.title as string,
				items:cartNote.items as string[],
				hash:cartNote.hash as string,
			}
			addToFav(favData)
		} else {
			removeFavByHash(hash)
		}
	};

		const [loading, setLoading] = useState(false);
		useEffect(() => {
			setLoading(favLoading);
			
		}, [favLoading]);
	
	  const [curRef, setCurRef] = useState<HTMLElement|null>(null);
	  
		const removeCartItem = (id:string) => {
			setDisabledID(id);
			remove(id as UUID);
		}

	const openNote = (id:string) => {
		if (isVKCOM) {
			routeNavigator.push(`/cart/mv/${id}`)
		} else {
			routeNavigator.push(`/cart/view/${id}`)
		}
	}

	  const CartItemPopout = (r:HTMLElement) => {
		return (
		  <ActionSheet mode="menu" onClose={()=>setCurRef(null)} toggleRef={r} iosCloseItem={<ActionSheetDefaultIosCloseItem/>}>
			<ActionSheetItem disabled={loading} onClick={() => openNote(r.id)} before={<Icon16ViewOutline />}>Открыть записку</ActionSheetItem>
			<ActionSheetItem disabled={loading} before={loading?<Spinner size="small" />:(hasFavByHash(r.nonce as string)?<Icon16Favorite/>:<Icon16FavoriteOutline />)} onClick={() => changeFavorite(r.id as UUID, r.nonce as string)}>{hasFavByHash(r.nonce as string)?"Убрать из избранного":"Добавить в избранное"}</ActionSheetItem>
			<ActionSheetItem disabled={loading} before={<Icon16DeleteOutline />} onClick={() => removeCartItem(r.id)} autoClose mode="destructive">Удалить</ActionSheetItem>
		  </ActionSheet>
		);
	  };


	  const BottomButtons = () => {
		return (
			<Div style={isVKCOM?{width:"60%", margin: "auto"}:{}}>
						
								<div  style={{ textAlign: "center" }}>
									Сумма пожертвования: {sumBy(cartItems, "amount")} ₽
								</div>
								<Spacing />
								<Button
									id="payment"
									stretched
									size="l"
									mode="primary"
									appearance="positive"
									onClick={createOrder}
								>
									Перейти к оплате
								</Button>
								<Spacing /> 
								<Button
									id="addNote"
									stretched
									size="l"
									mode="tertiary"
									appearance="accent"
									onClick={() => routeNavigator.push('/')}
								>
									Добавить еще одну записку
								</Button>
							
						</Div>
		)
	  }


	function sumBy(values:Zapiska[], key:any) {
		return values.reduce((total, value) => (total += value[key as keyof typeof value] as number), 0);
	}

	const createOrder = () => {
		var noteIDs = cartItems?.map(note => note.id) as UUID[];
		routeNavigator.showPopout(loadingSpinner);
		OrderAPI.create(noteIDs, !isVKCOM)
		.then((result) => {
			if (result.status === 201 && !isVKCOM && result.data.url !== "") {
				window.open(result.data.url,'_top');
				console.log("w top");
				//window.location.href = result.data.url;
				bridge.send('VKWebAppClose', {status: 'success'});
			} else if (result.status === 201 && isVKCOM && result.data.token !== "") {
				routeNavigator.push('/cart/checkout/'+result.data.oid+'/'+result.data.token)
			} else {
				notify("Ошибка. Попробуйте еще раз.","error");
			}
		}).catch((error) => {
			apiErrorNotify(error);
		}).finally(() => {
			return routeNavigator.hidePopout()
		}
		);
};

const handleIconButtonClick = (event:any, index:number) => {
    event.stopPropagation();
    curRef ? setCurRef(null) : setCurRef(eRef.current[index] as HTMLElement);
  };

	return (
		<Panel id="cart" nav={nav}>
			{curRef && CartItemPopout(curRef)}	
			<PanelHeader fixed>
				Мои записки
			</PanelHeader>
			<Group>
				<Spacing />
				{cartItems.length === 0 && (
					<Placeholder
						icon={<Icon32Cards2Outline />}
						header="Ничего не найдено"
						action={<Button size="m" onClick={() => routeNavigator.push('/')}>Подать записку</Button>}
					>
						Вы еще не заполнили ни одной записки
					</Placeholder>
				)}

				{cartItems.length > 0 && (
					<CardGrid
						size="l"
						style={isVKCOM?{width:"70%", margin: "auto"}:{}}
					>
						{cartItems.map((note, index) => {
							return (
								<Card
									key={note.id}
									mode={disabledID===note.id?"tint":"shadow"}
									style={disabledID===note.id?{opacity:.5}:{}}
								>
								<RichCell
									style={{paddingLeft:8, paddingRight: 2}}
									//hasHover={false}
									//hasActive={false}
									before={note.cat === "zdravie" ? <CrossRed width={48} height={48} style={{borderRadius:"4px"}} /> : <CrossBlack width={48} height={48} style={{borderRadius:"4px"}} />}
									text={note.title}
									caption={note.sub?note.sub:trebaCats[note.cat as keyof typeof trebaCats]}
									onClick={() => openNote(note.id as string)}
									after={
											<SimpleCell
												key={note.id}
												disabled={true}
												indicator={
													<Counter size="s">
														<Icon16User /> {note.items?.length as number}
													</Counter>
												}
												
												before={<Caption>{note.amount} ₽</Caption>}
												after={
													<IconButton
														id={note.id}
														nonce={note.hash}
														aria-label="Действия"
														hasHover={false}
														getRootRef={ref => {
															eRef.current[index] = ref
														}}
														onClick={(event)=>handleIconButtonClick(event, index)}
														//onClick={()=> {curRef?setCurRef(null):setCurRef(eRef.current[index] as HTMLElement)}}
													>
														<Icon16MoreVertical />
													</IconButton>
												}
											></SimpleCell>
									}
								/>
								</Card>
							);
						})}
					</CardGrid>
				)}
			</Group>
			{cartItems.length > 0 && cartItems.length <= 5 && (
				<FixedLayout vertical="bottom" filled>
					<BottomButtons/>
				</FixedLayout>
			)}
			{cartItems.length > 0 && cartItems.length > 5 && (
				<BottomButtons/>
			)}
			{/* {snackbar} */}
		</Panel>
		
	);
};
