//import xxhash from "xxhash-wasm";
//import XXH from "xxhashjs";

export function amount(arCnt: number, price: number, unit: number) {
	let amount = 0;
	let count = 0;
	if (unit > 1) {
		if (arCnt % unit === 0) {
			count = arCnt / unit;
		} else {
			//count = (arCnt - arCnt % unit) / unit;
			count = Math.floor(arCnt / unit);
			if (count < 1) {
				count = 1;
			}
			let cntDelta = arCnt - count * unit; // на сколько количество имен превышает кратность
			if (cntDelta > 5) {
				count++;
			}
		}
	} else {
		count = arCnt;
	}

	amount = price * count;

	return amount;
}

/* export async function xxhash3(str:string) {
	const { h32ToString } = await xxhash();
	return h32ToString(str);
} */

export function trimStrArray(arr:string[]) {
	return arr.map<string>(el => el.trim());
  }

export function declOfNum(n:number, titles:string[]) {
	return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2]
}

export function noteNum(n:number) {
	return n.toString()+" "+declOfNum(n, ['записка','записки','записок'])
}
