import React, { useState, useEffect } from "react";
import {
	Group,
	Panel,
	PanelHeader,
	PanelHeaderBack,
} from "@vkontakte/vkui";
import { NavProp, GoFunctionProp } from "../types";

import {
	useParams,
	useRouteNavigator
} from "@vkontakte/vk-mini-apps-router";
import YooWidget from "../components/YooKassa";

type DonateCheckoutProps = NavProp & GoFunctionProp;

const DonateCheckout = ({ nav, go }: DonateCheckoutProps) => {
	const { orderID, token }: any = useParams({ panel: nav }) ?? {};
	const [ done, setDone ] = useState<boolean>(false);
	const routeNavigator = useRouteNavigator();

	const onComplete = (successed:boolean) => {
		setDone(true);
	}

	useEffect(() => {
		if (done) {
			routeNavigator.replace(`/donate/status/${orderID}`);
		}
	},[done]);

	return (
		<Panel id="donate_checkout" nav={nav}>
			<PanelHeader before={<PanelHeaderBack onClick={() => routeNavigator.replace('/donate')} />}>
				Оплата
			</PanelHeader>
			<Group>
				{token && <YooWidget token={token as string} onComplete={onComplete} /> }
			</Group>
		</Panel>
	);

};
export default DonateCheckout;