import ReactDOM from "react-dom/client";
import api from "./api/api"
import "./index.css";
//import reportWebVitals from "./reportWebVitals";
import bridge from "@vkontakte/vk-bridge";
import { AppConfig } from "./AppConfig";
import AppError from "./AppError";


/* imageElement.onerror = () => {
					withImg = false;
				}
 */
bridge.send("VKWebAppInit");


var XXH = require('xxhashjs');

const imageElement = new Image();
const opts = localStorage.getItem('options') ? JSON.parse(localStorage.getItem('options')  || '{}') : null
if (opts && opts.picture && opts.picture!=="") {
	imageElement.src = opts.picture;
}

var etag = "" as string


const products = localStorage.getItem("products")||"{}";
const cart = localStorage.getItem("cartItems")||"[]";
const favorites = localStorage.getItem("favItems")||"[]";
const options = localStorage.getItem("options")||"{}";

etag += XXH.h64(products, 0xABCD).toString(16).substring(0,8);
etag += XXH.h64(cart, 0xABCD).toString(16).substring(0,8);
etag += XXH.h64(favorites, 0xABCD).toString(16).substring(0,8);
etag += XXH.h64(options, 0xABCD).toString(16).substring(0,8);

//const heads = (etag !== "") ? {'ETag': etag} : {};
const heads = (etag !== "") ? {'If-None-Match': etag} : {};

/* const testData = {
	"vk_access_token_settings": "",
	"vk_app_id": 51424529,
	"vk_are_notifications_enabled": 0,
	"vk_is_app_user": 1,
	"vk_is_favorite": 0,
	"vk_language": "ru",
	"vk_platform": "desktop_web",
	"vk_ref": "other",
	"vk_ts": 1697617858,
	"vk_user_id": 1749863,
	"sign": "WTWr4R-Fuc3EJVLTV6aC1qfcFFIOUBTclAeSgXP5PlY"
} */

const getToken = async (s: string) => {
	try {
		const data = await bridge.send('VKWebAppCreateHash', {payload: s})
		if (data.ts) {
			return btoa(JSON.stringify(data));
		} else {
			return "";
		}
  	} catch(e) {
		return "";
  	}
  };

/* function paramsFromObj(data:any) {
	return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
} */

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
//var aid = ""

//var withImg:boolean=false;	
const getInitData = async () => {
		
	var params:string = '';
		try {
			/* if (window.location.host==='localhost:10888') {
				params = "?"+paramsFromObj(testData);
			} else { */
				params = window.location.search;

				//let lp = new URLSearchParams(params);
				//aid = lp.get("vk_app_id")||"";
				//let uid = lp.get("vk_user_id")||"";
				//localStorage.setItem("init", aid.concat('-',uid));
				//const token = await getToken(aid.concat('-',uid))
				//sessionStorage.setItem("token",token)
			//}
			
		  const response = await api.get('/init'+params, { headers: heads })
		 
			if (response.status === 200 || response.status === 204) {
				let lp = new URLSearchParams(params);
				let aid = lp.get("vk_app_id")||"";
				let uid = lp.get("vk_user_id")||"";
				const token = await getToken(aid.concat('-',uid));
				sessionStorage.setItem("token",token);
				sessionStorage.setItem("platform",lp.get("vk_platform")||"");
				//localStorage.setItem("init", aid.concat('-',uid));
				
			}

		  if (response.status === 200) {
			if (response.data.options) {
				if (response.data.options.picture) {
					//withImg = true;
					imageElement.src = response.data.options.picture;
					imageElement.onerror = () => {
						//withImg = false;
						response.data.options.picture="";
					}
				}
				localStorage.setItem("options", JSON.stringify(response.data.options))
			}
			if (response.data.items) {
				localStorage.setItem("products", JSON.stringify(response.data.items))
			}
			if (response.data.cart) {
				localStorage.setItem("cartItems", JSON.stringify(response.data.cart))
			}
			if (response.data.favorites) {
				localStorage.setItem("favItems", JSON.stringify(response.data.favorites))
			}
			
		  }
		  
			/* if (withImg) {
				imageElement.onload = () => { root.render(<AppConfig/>);}
			} else { */
				root.render(<AppConfig/>);
			//}
		} catch (err:any) {
			root.render(<AppError/>);
		}
};
getInitData()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
