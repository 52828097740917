import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../context/DataContext";
import {
	Group,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	ButtonGroup,
	Button,
	Div,
	FixedLayout,
	Spacing,
	Title,
	usePlatform,
	Platform,
	Caption,
	Footer,
	Link
} from "@vkontakte/vkui";
import { NavProp, GoFunctionProp, Zapiska } from "../types";
import { DEFAULT_VIEW_PANELS, HOME_PANEL_MODALS } from "../routes";
import {
	useParams,
	useRouteNavigator,
	//useEnableSwipeBack,
} from "@vkontakte/vk-mini-apps-router";
import { ZapiskaForm } from "../forms/ZapiskaForm";
import { Icon20HelpOutline } from "@vkontakte/icons";
//import zdravie from "../img/zdravie.svg";
//import upokoenie from "../img/upokoenie.svg";
import { ZdravieTitle, UpokoenieTitle } from "../components/images";
import { declOfNum } from "../helpers";

import "../App.css"

type TrebaPanelProps = NavProp & GoFunctionProp & {
	subtitle: string;
	setSubtitle: any;  
};

export const Treba = ({ nav, go, subtitle, setSubtitle }: TrebaPanelProps) => {
	const { cat, type }: any = useParams({ panel: nav }) ?? {};
	const { products, getProduct } = useContext(DataContext);
	const [variation, setVariation] = useState<string>("");
	const platform = usePlatform();
	
	const isVKCOM = platform === Platform.VKCOM;
	//const isMobile = platform === Platform.ANDROID||Platform.IOS;

	const options = localStorage.getItem('options') ? JSON.parse(localStorage.getItem('options')  || '{}') : null

	useEffect(() => {
		if (!type) {
			setVariation("");
			setSubtitle("");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	//useEnableSwipeBack();
	const routeNavigator = useRouteNavigator();
	
	const SelectCategory = () => {
		const trebaCats = Object.keys(products).map(function (key) {
			return (
				<Button
					key={key}
					stretched
					size="l"
					mode="primary"
					appearance="accent-invariable"
					onClick={() => go(`/${DEFAULT_VIEW_PANELS.NEW}/${key}`)}
				>
					{products[key].title}
				</Button>
			);
		});
		return (
			<>
			<Group>
				{/* <Div style={isVKCOM?{width:"90%", margin: "auto", padding:"0px"}:{padding:"0px"}}> */}
				<Div style={{width:"90%", margin: "auto", padding:"0px"}}>
				{options.picture && 
				<img
					key="header"
					style={{ height: "auto", width: "100%" }}
					alt=""
					src={options.picture}
					//src={header}
				/>
				}
				</Div>
				<Spacing size={4} />
				{options.title && <Title weight="2" className="parishName">{options.title}</Title>}
				<Spacing size={8} />
				<ButtonGroup mode="vertical" stretched>
					{trebaCats}
					<Button
						stretched
						size="l"
						mode="tertiary"
						before={<Icon20HelpOutline />}
						onClick={() => go(`/${DEFAULT_VIEW_PANELS.INFO}`)}
					>
						О церковных записках
					</Button>
					{/* <Button
						stretched
						size="l"
						mode="tertiary"
						before={<Icon20InfoCircleOutline />}
					>
						Реквизиты прихода
					</Button> */}
				</ButtonGroup>
			</Group>
			
		</>
		)
	}
		 

	const SelectProduct = () => {
		const trebaList = products[cat].products.map((value,index) => {
			if (value.variable) {
				return (
					<Button
						id={value.id}
						key={value.id}
						stretched
						size="l"
						mode="outline"
						onClick={()=>{setVariation(value.id as string); routeNavigator.push(`/${DEFAULT_VIEW_PANELS.NEW}/${cat}/${HOME_PANEL_MODALS.VARIATION}/${value.id}`);}}
					>
						{value.title}<br /><Caption level="2" style={{color:"var(--vkui--color_text_muted)"}}>{value.price} ₽ за {value.unit} {declOfNum(value.unit, ['имя','имени','имен'])}</Caption>
					</Button>
				)
			}
			return (
				<Button
					key={value.id}
					stretched
					size="l"
					mode="outline"
					onClick={() => go(`/${DEFAULT_VIEW_PANELS.NEW}/${cat}/${value.id}`)}
				>
					{value.title}<br /><Caption level="2" style={{color:"var(--vkui--color_text_muted)"}}>{value.price} ₽ за {value.unit} {declOfNum(value.unit, ['имя','имени','имен'])}</Caption>
				</Button>
			);
		});
		return (
			<ButtonGroup mode="vertical" stretched>
				{trebaList}
			</ButtonGroup>
		)
	}
	var note:Zapiska;
	note = {};

	const NoteForm = () => {
		const product = getProduct(cat,type);
		note.pid = product.id as string;
		note.title = product.title as string;
		note.cat = cat as string;

		if (subtitle!=="") {
			note.sub = subtitle as string;
			
		}

		return (
			<>
				<div style={{ textAlign: "center" }}>
					<Title level="2">{product.title}</Title>
					{note.sub?<Title level="3" weight="3">{note.sub}</Title>:""}
				</div>
				<ZapiskaForm
					product={product}
					note={note}
				/>
			</>
		);
	}

	return (
		<Panel id="treba" nav={nav}>
			<PanelHeader
				before={ (cat || type) &&
					<PanelHeaderBack
						onClick={() => {
							if (cat && !type && !variation) {
								routeNavigator.replace("/");
							} else if (cat && !type && variation) {
								setVariation("");
								routeNavigator.replace(`/${DEFAULT_VIEW_PANELS.NEW}/${cat}`);
							} else {
								routeNavigator.back();
							}
						}
						}
					/>
				}
			>
				Подать записку
			</PanelHeader>
			<Group>
				<Div style={isVKCOM?{width:"60%", margin: "auto"}:{}}>
					{cat && (
						<div style={{ textAlign: "center" }}>
							{cat === "zdravie" ? 
								<ZdravieTitle height="50px" width="auto" />:
								<UpokoenieTitle height="50px" width="auto" />
							}
					</div>
					)}
					{!cat && <SelectCategory />}
					{cat && !type && <SelectProduct />}
					{cat && type && <NoteForm />}
				</Div>
			</Group>
			{!cat && <FixedLayout vertical="bottom" filled>
			<Div style={isVKCOM?{width:"60%", margin: "auto"}:{paddingBottom:"0px"}}>
				<Button
					stretched
					size="l"
					mode="outline"
					onClick={() => go(`/${HOME_PANEL_MODALS.DONATE}`)}
				>
					Пожертвовать
				</Button>
				{/* <Spacing size={16} /> */}
				{/* <Footer>Разработчик: <Link href={isVKCOM?"https://vk.com/id1749863":"vk://vk.com/id1749863"}>Иван Кузнецов</Link></Footer> */}
				{!isVKCOM && 
					<Footer>Разработчик: <Link href="vk://vk.com/id1749863">Иван Кузнецов</Link></Footer>
				}
			</Div>
			</FixedLayout>	
			}
		</Panel>
	);
};
