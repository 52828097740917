//https://semaphoreci.com/blog/api-layer-react
import api from "./api"
import { Donate, OrderStatus } from "../types"
import { UUID } from "crypto";

const PATH = '/donate';

export const DonateAPI = {
	create: async function (amount:number, mobile:boolean, cancel = false) {
		var body = {
			amount:amount,
			m:mobile,
		} as Donate;
		//body.amount = amount;
		amount = amount*100;
		return await api.post(
			PATH,
			body
		)
	},
	get: async function (id:UUID, cancel = false) {
		return await api.get<OrderStatus>(
			PATH+'/'+id
		)
	},
  }
 