import React, { useEffect, useState, useContext } from "react";
import bridge from "@vkontakte/vk-bridge";
//import { SplitCol, SplitLayout, View, Root, Epic, ModalRoot } from '@vkontakte/vkui';
import {
	Tabbar,
	TabbarItem,
	Counter,
	SplitCol,
	SplitLayout,
	View,
	Root,
	Epic,
	ModalRoot,
	Panel,
	PanelHeader,
	Group,
	Cell,
	useAdaptivityConditionalRender,
	usePlatform,
	Platform,
	Badge,
} from "@vkontakte/vkui";
import "@vkontakte/vkui/dist/vkui.css";

import {
	useActiveVkuiLocation,
	usePopout,
	useRouteNavigator,
	//useGetPanelForView,
} from "@vkontakte/vk-mini-apps-router";
import { CartContext } from "./context/ShoppingCartContext";

//import { Home } from "./panels/Home";
import Info from "./panels/Info";

import {
	//ALTERNATIVE_ROOT,
	//ALTERNATIVE_VIEW,
	//ALTERNATIVE_VIEW_PANELS,
	DEFAULT_ROOT,
	DEFAULT_VIEW,
	DEFAULT_VIEW_PANELS,
	//TREBA_VIEW,
	//TREBA_VIEW_PANELS,
	//SINODIK_VIEW,
	//SINODIK_VIEW_PANELS,
	//SINODIK_PANEL_MODALS,
	CART_VIEW,
	CART_VIEW_PANELS,
	CART_PANEL_MODALS,
	PROFILE_VIEW,
	PROFILE_VIEW_PANELS,
	FAVORITE_VIEW,
	FAVORITE_VIEW_PANELS,
	HOME_PANEL_MODALS,
	FAVORITE_PANEL_MODALS,
	PROFILE_VIEW_MODALS,
	DONATE_VIEW_PANELS,
	//HISTORY_VIEW_PANELS,
	//HISTORY_VIEW,
	//PAYMENT_VIEW,
	//PAYMENT_VIEW_PANELS,
	//EMPTY_VIEW,
	//EMPTY_VIEW_PANELS,
} from "./routes";

import { Treba } from "./panels/Treba";
import { TrebaVariationModal } from "./modals/TrebaVariationModal";
//import { Sinodik } from "./panels/Sinodik";
//import { SinodikAdd } from "./panels/SinodikAdd";
//import { SinodikAddList } from "./panels/SinodikAddList";
//import { SinodikEdit } from "./panels/SinodikEdit";
//import { SinodikAddNoteModal, SinodikAddNoteModalZ, SinodikAddNoteModalU } from "./modals/SinodikAddNoteModal";
import { Cart } from "./panels/Cart";
import { CartEditNote } from "./panels/CartEditNote";
import CartViewNote from "./panels/CartViewNote";
import { Favorites } from "./panels/Favorites";
import { Profile } from "./panels/Profile";
import { Payment } from "./panels/Payment";
//import { Empty } from './panels/Empty';
//import { SinodikAddModal } from "./modals/SinodikAddModal";
//import { UserModal } from "./modals/UserModal";
import { DonateModal } from "./modals/DonateModal";
import { CheckoutModal } from "./modals/CheckoutModal";
import { CartViewNoteModal } from "./modals/CartViewNote";
import { HistoryViewNoteModal } from "./modals/HistoryViewNote";
//import { NoteViewModal } from "./modals/NoteViewModal";
//import { CartModal } from './modals/CartModal';
//import { Alternative } from "./panels/Alternative._tsx_";
//import { OnboardingOne } from "./onboarding/OnboardingOne";
//import { OnboardingTwo } from "./onboarding/OnboardingTwo";
//import { OnboardingThree } from "./onboarding/OnboardingThree";
import { FavoritesContext } from "./context/FavoritesContext";

import {
	Icon28WriteSquareOutline,
	Icon28Cards2Outline,
	Icon28FavoriteOutline,
	Icon28HistoryBackwardOutline,
} from "@vkontakte/icons";
import { FavoritesView } from "./panels/FavoritesView";
import { FavoritesEdit } from "./panels/FavoritesEdit";
//import { AddNoteModal } from "./modals/AddNoteModal";
import { FavVariationModal } from "./modals/FavVariationModal";
import { FavViewNoteModal } from "./modals/FavViewNote";
import { FavAddNoteModal } from "./modals/FavAddNoteModal";
import CartCheckout from "./panels/CartCheckout";
import DonateCheckout from "./panels/DonateCheckout";
import { DonateStatus } from "./panels/DonateStatus";

function App() {
	const { favHasNew } = useContext(FavoritesContext);
	const [fetchedUser, setUser] = useState<any>(null);
	const [subtitle, setSubtitle] = useState("");
	const routerPopout = usePopout();
	const routeNavigator = useRouteNavigator();
	const {
		root: activeRoot = DEFAULT_ROOT,
		view: activeView = DEFAULT_VIEW,
		panel: activePanel = DEFAULT_VIEW_PANELS.HOME,
		modal: activeModal,
		panelsHistory,
	} = useActiveVkuiLocation();

	useEffect(() => {
		async function fetchData() {
			const user = await bridge.send("VKWebAppGetUserInfo");
			setUser(user);
		}
		fetchData();
	}, []);

	const go = (path: string) => {
		routeNavigator.push(path);
	};

	const { cartItems } = useContext(CartContext);
	const modal = (
		<ModalRoot
			activeModal={activeModal}
			onClose={() => routeNavigator.hideModal()}
		>
			
			
			<DonateModal nav={HOME_PANEL_MODALS.DONATE}></DonateModal>
			<TrebaVariationModal nav={HOME_PANEL_MODALS.VARIATION} setSubtitle={setSubtitle}></TrebaVariationModal>
			
			{/* <SinodikAddNoteModal nav={SINODIK_PANEL_MODALS.ADD_NOTE} go={go} cat=""></SinodikAddNoteModal>
			<SinodikAddNoteModalZ nav={SINODIK_PANEL_MODALS.ADD_NOTE_Z} go={go}></SinodikAddNoteModalZ>
			<SinodikAddNoteModalU nav={SINODIK_PANEL_MODALS.ADD_NOTE_U} go={go}></SinodikAddNoteModalU> */}
			
			{/* <AddNoteModal nav={FAVORITE_PANEL_MODALS.ADD_NOTE} go={go}></AddNoteModal> */}
			<FavVariationModal nav={FAVORITE_PANEL_MODALS.VARIATION}></FavVariationModal>
			<FavViewNoteModal nav={FAVORITE_PANEL_MODALS.VIEW} go={go}></FavViewNoteModal>
			<FavAddNoteModal nav={FAVORITE_PANEL_MODALS.ADD_NOTE_MODAL} go={go}></FavAddNoteModal>

			<CheckoutModal nav={CART_PANEL_MODALS.SUCCESS}></CheckoutModal>
			<CartViewNoteModal nav={CART_PANEL_MODALS.VIEW} go={go}></CartViewNoteModal>
			<HistoryViewNoteModal nav={PROFILE_VIEW_MODALS.VIEW_NOTE} go={go}></HistoryViewNoteModal>
			{/* <NoteViewModal nav={CART_PANEL_MODALS.VIEW} go={go}></NoteViewModal> */}
			{/* <CartModal nav={HOME_PANEL_MODALS.CART}></CartModal> */}
			{/* <OnboardingOne nav={HOME_PANEL_MODALS.ONBOARDING_1} />
			<OnboardingTwo nav={HOME_PANEL_MODALS.ONBOARDING_2} />
			<OnboardingThree nav={HOME_PANEL_MODALS.ONBOARDING_3} /> */}
		</ModalRoot>
	);

	const [activeStory, setActiveStory] = useState(activeView);

	const platform = usePlatform();
	const { viewWidth } = useAdaptivityConditionalRender();
	const activeStoryStyles = {
		backgroundColor: "var(--vkui--color_background_secondary)",
		borderRadius: 8,
	};

	const onStoryChange = (e: any) => {
		setActiveStory(e.currentTarget.dataset.story);
		if (e.currentTarget.dataset.story === DEFAULT_VIEW_PANELS.HOME) {
			routeNavigator.push("/");
		} else {
			routeNavigator.push("/" + e.currentTarget.dataset.story);
		}
	};
	const isVKCOM = platform === Platform.VKCOM;
	const history = activeModal || routerPopout ? [] : panelsHistory;
	return (
		<SplitLayout popout={routerPopout} modal={modal} aria-busy={!!routerPopout}>
			{viewWidth.tabletPlus && (
				<SplitCol
					className={viewWidth.tabletPlus.className}
					fixed
					width={280}
					maxWidth={280}
				>
					<Panel>
						{isVKCOM && <PanelHeader />}
						<Group>
							
							<Cell
								style={
									activeStory === DEFAULT_VIEW_PANELS.HOME
										? activeStoryStyles
										: undefined
								}
								data-story={DEFAULT_VIEW_PANELS.HOME}
								onClick={onStoryChange}
								before={<Icon28WriteSquareOutline />}
							>
								Подать записку
							</Cell>
							{/* <Cell
								style={
									activeStory === SINODIK_VIEW_PANELS.HOME
										? activeStoryStyles
										: undefined
								}
								data-story={SINODIK_VIEW_PANELS.HOME}
								onClick={onStoryChange}
								before={<Icon28UsersOutline />}
							>
								Помянник
							</Cell> */}
							<Cell
								style={
									activeStory === CART_VIEW_PANELS.HOME
										? activeStoryStyles
										: undefined
								}
								data-story={CART_VIEW_PANELS.HOME}
								onClick={onStoryChange}
								before={<Icon28Cards2Outline />}
							>
								Мои записки
							</Cell>
							<Cell
								style={
									activeStory === FAVORITE_VIEW_PANELS.HOME
										? activeStoryStyles
										: undefined
								}
								data-story={FAVORITE_VIEW_PANELS.HOME}
								onClick={onStoryChange}
								before={<Icon28FavoriteOutline />}
							>
								Избранное
							</Cell>
							{/* <Cell
								style={
									activeStory === HISTORY_VIEW_PANELS.HOME
										? activeStoryStyles
										: undefined
								}
								data-story={HISTORY_VIEW_PANELS.HOME}
								onClick={onStoryChange}
								before={<Icon28UserCircleOutline />}
							>
								Профиль
							</Cell> */}
							<Cell
								style={
									activeStory === PROFILE_VIEW_PANELS.HOME
										? activeStoryStyles
										: undefined
								}
								data-story={PROFILE_VIEW_PANELS.HOME}
								onClick={onStoryChange}
								//before={<Icon28UserCircleOutline />}
								before={<Icon28HistoryBackwardOutline/>}
							>
								История
							</Cell>
						</Group>
					</Panel>
				</SplitCol>
			)}
			<SplitCol>
				<Epic
					activeStory={activeRoot}
					tabbar={
						viewWidth.tabletMinus && (
							<Tabbar className={viewWidth.tabletMinus.className}>
								
								<TabbarItem
									onClick={onStoryChange}
									selected={activeView === DEFAULT_VIEW}
									data-story={DEFAULT_VIEW_PANELS.HOME}
									text="Подать записку"
								>
									<Icon28WriteSquareOutline />
								</TabbarItem>
								{/* <TabbarItem
									onClick={onStoryChange}
									selected={activeView === SINODIK_VIEW}
									data-story={SINODIK_VIEW_PANELS.HOME}
									text="Помянник"
								>
									<Icon28UsersOutline />
								</TabbarItem> */}
								<TabbarItem
									onClick={onStoryChange}
									selected={activeView === CART_VIEW}
									data-story={CART_VIEW_PANELS.HOME}
									text="Мои записки"
									indicator={
										<Counter size="s" mode="prominent">
											{cartItems.length}
										</Counter>
									}
								>
									<Icon28Cards2Outline />
								</TabbarItem>
								<TabbarItem
									onClick={onStoryChange}
									selected={activeView === FAVORITE_VIEW}
									data-story={FAVORITE_VIEW_PANELS.HOME}
									text="Избранное"
									indicator={favHasNew?<Badge mode="prominent"/>:undefined}
								>
									<Icon28FavoriteOutline />
								</TabbarItem>
								{/* <TabbarItem
									onClick={onStoryChange}
									selected={activeView === HISTORY_VIEW}
									data-story={HISTORY_VIEW_PANELS.HOME}
									//indicator={<Badge mode="prominent" />}
									text="Профиль"
								>
									<Icon28UserCircleOutline />
								</TabbarItem> */}
								<TabbarItem
									onClick={onStoryChange}
									selected={activeView === PROFILE_VIEW}
									data-story={PROFILE_VIEW_PANELS.HOME}
									//indicator={<Badge mode="prominent" />}
									text="История"
								>
									{/* <Icon28UserCircleOutline /> */}
									<Icon28HistoryBackwardOutline/>
								</TabbarItem>
							</Tabbar>
						)
					}
				>
					<Root activeView={activeView} nav={DEFAULT_ROOT}>
						<View
							id={DEFAULT_VIEW_PANELS.HOME}
							nav={DEFAULT_VIEW}
							history={history}
							activePanel={activePanel || DEFAULT_VIEW_PANELS.HOME}
							//onSwipeBack={() => routeNavigator.back()}
						>
							{/* <Home
								nav={DEFAULT_VIEW_PANELS.HOME}
								fetchedUser={fetchedUser}
								go={go}
							/> */}
							<Treba nav={DEFAULT_VIEW_PANELS.HOME} go={go} subtitle={subtitle} setSubtitle={setSubtitle} />
							<Info nav={DEFAULT_VIEW_PANELS.INFO} />

							<DonateCheckout nav={DONATE_VIEW_PANELS.CHECKOUT} go={go} />
							<DonateStatus nav={DONATE_VIEW_PANELS.STATUS} />
							<Payment nav={CART_VIEW_PANELS.ORDER} />

						</View>
						<View
							id={CART_VIEW_PANELS.HOME}
							nav={CART_VIEW}
							history={history}
							activePanel={activePanel || CART_VIEW_PANELS.HOME}
							//onSwipeBack={() => routeNavigator.back()}
						>
							<Cart nav={CART_VIEW_PANELS.HOME} go={go} />
							<CartViewNote nav={CART_VIEW_PANELS.VIEW} go={go} />
							<CartEditNote nav={CART_VIEW_PANELS.EDIT} go={go} />
							<CartCheckout nav={CART_VIEW_PANELS.CHECKOUT} go={go} />
							<Payment nav={CART_VIEW_PANELS.ORDER} />
						</View>
						<View
							id={FAVORITE_VIEW_PANELS.HOME}
							nav={FAVORITE_VIEW}
							history={history}
							activePanel={activePanel || FAVORITE_VIEW_PANELS.HOME}
							//onSwipeBack={() => routeNavigator.back()}
						>
							<Favorites nav={FAVORITE_VIEW_PANELS.HOME} go={go} />
							<FavoritesView nav={FAVORITE_VIEW_PANELS.VIEW} go={go} />
							<FavoritesEdit nav={FAVORITE_VIEW_PANELS.EDIT} go={go} />
						</View>
						{/* <View
							id={HISTORY_VIEW_PANELS.HOME}
							nav={HISTORY_VIEW}
							history={history}
							activePanel={HISTORY_VIEW_PANELS.HOME}
							onSwipeBack={() => routeNavigator.back()}
						>
							<History
								nav={HISTORY_VIEW_PANELS.HOME}
								go={go}
								//fetchedUser={fetchedUser}
							/>
						</View> */}
						<View
							id={PROFILE_VIEW_PANELS.HOME}
							nav={PROFILE_VIEW}
							history={history}
							activePanel={PROFILE_VIEW_PANELS.HOME}
							//onSwipeBack={() => routeNavigator.back()}
						>
							<Profile
								nav={PROFILE_VIEW_PANELS.HOME}
								go={go}
								fetchedUser={fetchedUser}
							/>
						</View>
						{/* <View
							id={PAYMENT_VIEW_PANELS.HOME}
							nav={PAYMENT_VIEW}
							//history={history}
							activePanel={PAYMENT_VIEW_PANELS.HOME}
							onSwipeBack={() => routeNavigator.back()}
						>
							<Payment nav={PAYMENT_VIEW_PANELS.HOME} />
						</View> */}
						{/* <View
              nav={EMPTY_VIEW}
              history={history}
              activePanel={emptyActivePanel || EMPTY_VIEW_PANELS.EMPTY}
              onSwipeBack={() => routeNavigator.back()}
            >
              <Empty nav={EMPTY_VIEW_PANELS.EMPTY} />
            </View> */}
					</Root>
					{/* <Root activeView={activeView} nav={ALTERNATIVE_ROOT}>
						<View
							nav={ALTERNATIVE_VIEW}
							history={history}
							activePanel={
								alternativeActivePanel || ALTERNATIVE_VIEW_PANELS.ALTERNATIVE
							}
							onSwipeBack={() => routeNavigator.back()}
						>
							<Alternative nav={ALTERNATIVE_VIEW_PANELS.ALTERNATIVE} go={go} />
						</View>
					</Root> */}
				</Epic>
			</SplitCol>
		</SplitLayout>
	);
}

export default App;
