import React, { useState, ChangeEvent } from "react";
import {
	ModalCard,
	FormLayout,
	FormItem,
	Input,
	Button,
	usePlatform,
	Platform,
} from "@vkontakte/vkui";
import { DonateAPI } from "../api/donate";
import { NavProp } from "../types";
import { Icon16RoubleOutline } from "@vkontakte/icons";
import { useNotification } from '../context/NotificationContext';
import {
	useRouteNavigator,
} from "@vkontakte/vk-mini-apps-router";
import bridge from "@vkontakte/vk-bridge";

export const DonateModal = ({ nav }: NavProp) => {
	const platform = usePlatform();
	const isVKCOM = platform === Platform.VKCOM;
	const { notify, apiErrorNotify } = useNotification();
	const routeNavigator = useRouteNavigator();
	const [val, setVal] = useState("");
	const [loading, setLoading] = useState(false);
	
	const handleKeyDown = (e: ChangeEvent<HTMLInputElement>) => {
		if (parseInt(e.target.value)>0) {

		
		setVal((v) => (e.target.validity.valid ? e.target.value : v));
	} else {
		setVal("");
	}
	};
	
	const createDonate = () => {
		setLoading(true);
		var amount:number = parseInt(val, 10);
		DonateAPI.create(amount, !isVKCOM)
		.then((result) => {
			if (result.status === 201 && !isVKCOM && result.data.url !== "") {
				window.open(result.data.url,'_top');
				console.log("w top");
				//window.location.href = result.data.url;
				bridge.send('VKWebAppClose', {status: 'success'});
			} else if (result.status === 201 && isVKCOM && result.data.token !== "") {
				routeNavigator.push('/donate/checkout/'+result.data.oid+'/'+result.data.token)
			} else {
				notify("Ошибка. Попробуйте еще раз.","error");
			}
			/* if (result.status === 201 && result.data.token !== "") {
				routeNavigator.push('/donate/checkout/'+result.data.oid+'/'+result.data.token)
			} */
		}).catch((error) => {
			apiErrorNotify(error);
		}).finally(() => {
			setLoading(false);
		}
		);
	};

	return (
		<ModalCard id={nav} header="Пожертвование">
			<FormLayout>
				<FormItem htmlFor="example" top="Сумма:">
					<Input
						id="donate_sum"
						pattern="[0-9]*"
						after={<Icon16RoubleOutline />}
						inputMode="decimal"
						align="left"
						autoFocus
						placeholder="0"
						min="0"
						value={val ?? ""}
						onChange={handleKeyDown}
					/>
				</FormItem>
				<FormItem>
					<Button
						disabled={!val}
						id="donate_payment"
						stretched
						size="l"
						mode="primary"
						appearance="positive"
						onClick={createDonate}
						loading={loading}
					>
						Перейти к оплате
					</Button>
				</FormItem>
			</FormLayout>
		</ModalCard>
	);
};
