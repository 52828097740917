import React, { createContext, useState, useEffect } from 'react'
import { Error, Order } from '../types';
import { UUID } from 'crypto';



type HisContext = {
	hasMore:boolean,
	historyPage:number,
	updateHistoryHasMore:(status:boolean) => void, 
    historyItems:Order[],
    addToHistory:(items:Order[]) => void,
	updateHistoryPage:(page:number) => void,
	updateHistoryTotal:(t:number) => void,
    clearHistory:() => void, // ?
	total:number,
	setTotal:(t:number) => void,
	getOrderById:(id:UUID) => Order,
	updateOrderInHistory:(order:Order) => void,
}


export const HistoryContext = createContext({} as HisContext)

export const HistoryProvider = ({children}:any) => {
	//const [historyError, setHistoryError] = useState<Error|null>(null);
	//const [historyLoading, setHistoryLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [historyItems, setHistoryItems] = useState<Order[]>([]);
	//const [historyPages, setHistoryPages] = useState(0);
	const [historyPage, setHistoryPage] = useState(2);
	const [total, setTotal] = useState(0);
	const updateHistoryHasMore = (status: boolean) => setHasMore(status);
	const updateHistoryPage = (page: number) => setHistoryPage(page);
	const updateHistoryTotal = (t: number) => setTotal(t);
	
	const addToHistory = (items:Order[]) => {
		
			setHistoryItems([...historyItems, ...items]);
			//setHistoryPages(historyItems.length)
	}
	
  
	const updateOrderInHistory = (newItem:Order) => {
		//const newOrder = historyItems.slice();
		 const existingObj = historyItems.find((item:Order) => item.id === newItem.id);
		 if(existingObj) {
			 Object.assign(existingObj, newItem);
			 setHistoryItems(historyItems)
		 }
	};
  


   


  const getOrderById = (id:UUID) => {
	return historyItems.filter((item:any) => item.id === id)[0] as Order ?? null;
  };

  const clearHistory = () => {
	setHistoryItems([]);
	setTotal(0);
	setHistoryPage(2);
	//setHistoryPages(0);
	setHasMore(true);
  };

  useEffect(() => {
    localStorage.setItem("historyItems", JSON.stringify(historyItems));
  }, [historyItems]);

  useEffect(() => {
    const historyItems = localStorage.getItem("history");
    if (historyItems) {
      setHistoryItems(JSON.parse(historyItems));
    }
  }, []);



  return (
    <HistoryContext.Provider
      value={{
		//favError,
		//favLoading,
		hasMore,
		updateHistoryHasMore,
		//addFavError,
		//removeFavError,
        historyItems,
		historyPage,
        addToHistory,
        clearHistory,
		//getFavNote,
		//hasFavByHash,
		total,
		setTotal,
		updateHistoryPage,
		updateHistoryTotal,
		getOrderById,
		updateOrderInHistory,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};
