import React, { createContext, useState, useEffect } from 'react'
import { Favorite } from '../types';
import { UUID } from 'crypto';
import { FavoritesAPI } from '../api/favorites';
import { useNotification } from '../context/NotificationContext';

type FavContext = {
	favLoading:boolean,
	favHasNew:boolean,
	setFavHasNew:(status:boolean) => void, 
    favItems:Favorite[],
    addToFav:(item:Favorite) => void,
	removeFromFav:(id:UUID) => void,
	updateNoteInFav:(newItem:Favorite) => void,
    clearFav:() => void, // ?
	getFavNote:(id:UUID) => Favorite, // получить записку по id
	hasFavByHash:(hash:string) => boolean,
	removeFavByHash:(hash:string) => void,
}


export const FavoritesContext = createContext({} as FavContext)

export const FavoritesProvider = ({children}:any) => {
	const { apiErrorNotify } = useNotification();
	const [favLoading, setFavLoading] = useState(false);
	const [favHasNew, setHasNew] = useState(false);
	const [favItems, setFavItems] = useState(localStorage.getItem('favItems') ? JSON.parse(localStorage.getItem('favItems')  || '{}') : [])

	const setFavHasNew = (status: boolean) => setHasNew(status);
	
	const addToFav = async (item:Favorite) => {
		await setFavLoading(true);
		await FavoritesAPI.create(item.id as UUID)
		.then((result) => {
			item.id = result.data.id;
			item.hash = result.data.hash;
			setFavItems([item,...favItems]);
			setHasNew(true);
		}).catch((error) => {
			apiErrorNotify(error);
		}).finally(() => {
			setFavLoading(false);
		}
		);
	};

  const updateNoteInFav = (newItem:Favorite) => {
	   const newFav = favItems.slice();
		const existingObj = favItems.find((item:Favorite) => item.id === newItem.id);
		if(existingObj) {
			Object.assign(existingObj, newItem);
			localStorage.setItem('favItems', JSON.stringify(favItems))
		} else {
			newFav.push(newItem)
			localStorage.setItem('favItems',JSON.stringify(newFav))
		}
   };

  const removeFromFav = async (id:UUID) => {
		await setFavLoading(true);
		await FavoritesAPI.delete(id)
		.then((result) => {
			setFavItems(favItems.filter((item:any) => item.id !== id));
			setHasNew(false);
		}).catch((error) => {
			apiErrorNotify(error);
		}).finally(() => {
			setFavLoading(false);
		}
		);
   };


   const removeFavByHash = async (hash:string) => {
		const fav = favItems.filter((cartItem:any) => cartItem.hash === hash)[0] as Favorite ?? null;
		if (fav) {
			removeFromFav(fav.id as UUID)
		}
   };


  const getFavNote = (id:UUID) => {
	return favItems.filter((cartItem:any) => cartItem.id === id)[0] as Favorite ?? null;
  };

  const hasFavByHash = (hash:string) => {
	return favItems.some((cartItem:any) => cartItem.hash === hash)
  };

  const clearFav = () => {
    setFavItems([]);
  };

  

  useEffect(() => {
    localStorage.setItem("favItems", JSON.stringify(favItems));
  }, [favItems]);

  useEffect(() => {
    const favItems = localStorage.getItem("favItems");
    if (favItems) {
      setFavItems(JSON.parse(favItems));
    }
  }, []);



  return (
    <FavoritesContext.Provider
      value={{
		favLoading,
		favHasNew,
		setFavHasNew,
        favItems,
        addToFav,
		updateNoteInFav,
        removeFromFav,
        clearFav,
		getFavNote,
		hasFavByHash,
		removeFavByHash,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
