import React, { useContext, useRef, useState } from "react";

import {
	Button,
	Div,
	ModalPage,
	Group,
	Title,
	MiniInfoCell,
} from "@vkontakte/vkui";

import {
	useParams,
	useRouteNavigator,
} from "@vkontakte/vk-mini-apps-router";
import { NavProp, GoFunctionProp, Zapiska } from "../types";
//import zdravie from "../img/zdravie.svg";
//import upokoenie from "../img/upokoenie.svg";
import { HistoryContext } from "../context/HistoryContext";
import { CART_VIEW_PANELS } from "../routes";
import { DataContext } from "../context/DataContext";
import { CartContext } from "../context/ShoppingCartContext";
import { UUID } from "crypto";
import { amount } from "../helpers";
import { NotesAPI } from "../api/notes";
import { Icon20CheckCircleOutline } from "@vkontakte/icons";
import { useNotification } from '../context/NotificationContext';
import { UpokoenieTitle, ZdravieTitle } from "../components/images";

type  HistoryViewNoteProps = NavProp  & GoFunctionProp;

export const HistoryViewNoteModal = ({ nav, go }:  HistoryViewNoteProps) => {
	const routeNavigator = useRouteNavigator();
	const { apiErrorNotify } = useNotification();
	const { orderId, noteId }: any = useParams({ panel: nav }) ?? null;

	const { addToCart, inCartByHash } = useContext(CartContext);
	const { getOrderById } = useContext(HistoryContext);
	const { getProduct } = useContext(DataContext);

	const [waiting, setWaiting] = useState(false);
	const order = useRef(getOrderById(orderId) ?? {})
	
	const note = useRef(order.current.items?.filter((item:any) => item.id === noteId)[0] as Zapiska ?? {})

	const [inCart, setInCart] = useState(inCartByHash(note.current.hash as string))

	async function noteToCart() {
		setWaiting(true);
		let newNote = note.current as Zapiska;
		let product = getProduct(newNote.cat as string, newNote.pid as UUID)

		newNote.amount = newNote.items?amount(newNote.items.length, product.price, product.unit):0
		await NotesAPI.create(newNote)
		.then((result) => {
			newNote.id = result.data.i;
			newNote.hash = result.data.h;
			newNote.amount = result.data.a;
			addToCart(newNote);
			setInCart(true);
			setWaiting(false);
		}).catch((error) => {
			setWaiting(false);
			apiErrorNotify(error);
		})
		};
	

	return (
		<ModalPage
			id={nav}
			nav={nav}
			dynamicContentHeight
			onClose={()=>routeNavigator.hideModal()}
		>
			<Group>
			
				<div style={{ textAlign: "center" }}>
					{note.current.cat === "zdravie" ? 
						<ZdravieTitle height="32px" width="auto" />:
						<UpokoenieTitle height="32px" width="auto" />
					}
					{/* <img
						key={note.current.cat}
						style={{ height: "32px", width: "auto", margin: "auto" }}
						loading="lazy"
						alt=""
						src={note.current.cat === "zdravie" ? zdravie : upokoenie}
					/> */}
						<Title level="2">{note.current.title}</Title>
						{note.current.sub?<Title level="3" weight="3">{note.current.sub}</Title>:""}
				</div>
				<ol className="noteNames">
					{note.current.items?.map((item, index) => {
							return (
								<li className={note.current.cat} key={index}><i>{item}</i></li>
							);
					})}
				</ol>
			
			{inCart && (
				<MiniInfoCell before={<Icon20CheckCircleOutline />} textWrap="full">
				Записка добавлена в заявку
			  	</MiniInfoCell>
				)}
			<Div>
					<Button
						id="createNote"
						stretched
						size="l"
						mode="primary"
						appearance={inCart?"accent":"positive"}
						disabled={waiting}
						loading={waiting}
						onClick={()=> {inCart?routeNavigator.replace(`/${CART_VIEW_PANELS.HOME}`):noteToCart()}}
					>
						{inCart?"Перейти в заявку":"Добавить в заявку"}
					</Button>
				</Div>
			</Group>
		</ModalPage>
	);
};