import React, { useState, useContext, useEffect, useRef } from "react";
import { DataContext } from "../context/DataContext";
import {
	Button,
	ButtonGroup,
	PanelHeaderBack,
	FormLayout,
	FormItem,
	Textarea,
	RadioGroup,
	Radio,
	Div,
	ModalPage,
	ModalPageHeader,
	Caption,
	Spacing,
} from "@vkontakte/vkui";

import {
	useParams,
	useRouteNavigator,
} from "@vkontakte/vk-mini-apps-router";
import { NavProp, GoFunctionProp, Product } from "../types";
import zdravie from "../img/zdravie.svg";
import upokoenie from "../img/upokoenie.svg";
import { NotesAPI } from "../api/notes";
import { CartContext } from "../context/ShoppingCartContext";
import { FavoritesContext } from "../context/FavoritesContext";
import { loadingSpinner } from "../components/spinners";
import { CART_VIEW_PANELS } from "../routes";
import { amount } from "../helpers";
import { Zapiska } from "../types";
import { UUID } from "crypto";
import { declOfNum } from "../helpers";
import { useNotification } from '../context/NotificationContext';

type FavAddNoteProps = NavProp  & GoFunctionProp;

export const FavAddNoteModal = ({ nav, go }: FavAddNoteProps) => {
	const { noteId }: any = useParams({ panel: nav }) ?? null;
	const { notify, apiErrorNotify } = useNotification();
	const { getFavNote } = useContext(FavoritesContext);
	const { getProductsByCat, getProduct } = useContext(DataContext);
	const { addToCart } = useContext(CartContext);
	const [variable, setVariable] = useState<UUID|null>(null);
	const [subtitle, setSubtitle] = useState("");
	const routeNavigator = useRouteNavigator();
	const favNote = useRef(getFavNote(noteId) ?? {})
	
	const [ product, setProduct] = useState<Product|null>(null);
	
	const [disable, setDisable] = useState(true);
	const [custom, setCustom] = useState(false);
	const [modalBefore, setModalBefore] = useState<any|null>(null);
	const [modalHeader, setModalHeader] = useState<any>("");

	
	const productList = getProductsByCat(favNote.current.cat);

	useEffect(() => {
		if (variable) {
			setProduct(getProduct(favNote.current.cat, variable));
		} else {
			setProduct(null);
		}
	}, [variable, favNote.current.cat, getProduct]);

	useEffect(() => {
		if (product) {
			setModalHeader(<ModalPageHeader before={modalBefore?modalBefore:undefined} separator={false}>{product.title}</ModalPageHeader>);
		} else {
			setModalHeader(<div style={{ textAlign:"center", paddingTop:"20px" }}><img style={{ height: "32px", margin:"auto" }} src={favNote.current.cat === "zdravie" ? zdravie : upokoenie } alt={favNote.current.cat === "zdravie" ? "О здравии" : "О упокоении" } /><div style={{textAlign:"center"}}>Выберите тип записки:</div></div>);
		}
	}, [product, favNote.current.cat]);

	useEffect(() => {
		subtitle.length>9?setDisable(false):setDisable(true);
	}, [subtitle]);

	useEffect(() => {
		if (variable) {
			if (custom) {
			setModalBefore(<PanelHeaderBack onClick={() => setCustom(false)} />);
		} else {
			setModalBefore(<PanelHeaderBack onClick={() => setVariable(null)} />);
		}
		} else {
			setModalBefore(null);
		}
	}, [variable, custom]);

	async function save(productObj:Product) {
		let note:Zapiska = {}
		await routeNavigator.showPopout(loadingSpinner)

		note.cat = favNote.current.cat;
		note.pid = productObj.id;
		note.title = productObj.title;
		note.items = favNote.current.items;
		if (productObj.variable && subtitle.length>0) {
			note.sub = subtitle;
		}
		note.amount = note.items?amount(note.items.length, productObj.price, productObj.unit):0
		await NotesAPI.create(note)
		.then((result) => {
			note.id = result.data.i;
			note.hash = result.data.h;
			note.amount = result.data.a;
			addToCart(note);
			notify('Записка успешно добавлена','success');
			routeNavigator.replace(`/${CART_VIEW_PANELS.HOME}`);
		}).catch((error) => {
			apiErrorNotify(error);
		})
		};

	const closeVariationModal = () => {
		setVariable(null);
		setSubtitle("");
		routeNavigator.hideModal();
	}

	const textareaChange = (e:any) => {
		setSubtitle(e.target.value)
	}

	return (
		<ModalPage
			id={nav}
			nav={nav}
			header={modalHeader}
			dynamicContentHeight
			onClose={()=>routeNavigator.hideModal()}
		>
			{product && variable && custom &&
				<FormLayout>
					<FormItem>
						<Textarea rows={1} minLength={10} maxLength={200} placeholder="Кому" autoFocus onInput={textareaChange} />
					</FormItem>

				</FormLayout>
			}
			{product && variable && !custom && 
				<RadioGroup>
				{product.variations?.map((val, i) => (
					<Radio key={i} name="variation" value={val.title} onChange={()=>setSubtitle(val.title)}>{val.title}</Radio>
				))}
					<Radio key="custom" name="variation" onChange={()=>{setSubtitle("");setCustom(true);}}>Свой вариант</Radio>
				</RadioGroup>
			}
			{product && variable && 
				<Div>
				<ButtonGroup mode="horizontal" stretched>
					<Button mode="primary" stretched disabled={disable} onClick={()=>save(product)}>Выбрать</Button>
					<Button mode="tertiary" appearance="negative" stretched onClick={closeVariationModal}>Отмена</Button>
				</ButtonGroup>
				</Div>
			}
			

			{!variable && 
				<Div>
				<ButtonGroup mode="vertical" stretched>
				{favNote && favNote.current.cat && productList && productList.map((value) => {
					if (value.variable) {
						return (
							<Button
							key={value.id}
							stretched
							size="l"
							mode="outline"
							onClick={()=>setVariable(value.id as UUID)}
						>
							{value.title}<br /><Caption level="2" style={{color:"var(--vkui--color_text_muted)"}}>{value.price} ₽ за {value.unit} {declOfNum(value.unit, ['имя','имени','имен'])}</Caption>
						</Button>						
						);
					} else {
					return (
						<Button
							key={value.id}
							stretched
							size="l"
							mode="outline"
							onClick={() => save(value)}
						>
							{value.title}<br /><Caption level="2" style={{color:"var(--vkui--color_text_muted)"}}>{value.price} ₽ за {value.unit} {declOfNum(value.unit, ['имя','имени','имен'])}</Caption>
						</Button>
					);
					}
				})}
				</ButtonGroup>
				<Spacing />
				</Div>
			}
		</ModalPage>
	);
};