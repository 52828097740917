//import React, { useState } from "react";
import {
	Panel,
	PanelHeader,
} from "@vkontakte/vkui";

/* import {
	useFirstPageCheck,
	useRouteNavigator,
} from "@vkontakte/vk-mini-apps-router"; */
//import { useParams, useFirstPageCheck, useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import { NavProp, GoFunctionProp, UserInfo } from "../types";
//import { routes } from '../routes';
//import { AppMap } from '../appMap/AppMap';
//import { Icon16Dropdown } from '@vkontakte/icons';
//import { Icon56HistoryOutline } from "@vkontakte/icons";
//import { Icon56UserCircleOutline } from "@vkontakte/icons";
import { History } from "./History";

//const IMAGES = { persik, persik_fish, persik_sad };

type ProfilePanelProps = NavProp &
	GoFunctionProp & {
		fetchedUser: UserInfo;
	};

export const Profile = ({ nav, go, fetchedUser }: ProfilePanelProps) => {
	//const [params, setParams] = useSearchParams();
	//const [additional, setAdditional] = useState(params.get('additional'));
	//const [mode, setMode] = useState('all');
	
	//const routeNavigator = useRouteNavigator();
	return (
		<Panel id="profile" nav={nav}>
			<PanelHeader
				fixed
			>
				История
			</PanelHeader>
			
				<History go={go} />	
			
		</Panel>
	);
};