import React from "react";
import {
	ModalCard,
	Button,
	ButtonGroup,
	Caption
} from "@vkontakte/vkui";
import { NavProp } from "../types";
import { Icon28CheckCircleFill } from "@vkontakte/icons";

import { TREBA_VIEW_PANELS } from "../routes";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";

export const CheckoutModal = ({nav}:NavProp) => {
	const routeNavigator = useRouteNavigator();
	return (
		<ModalCard id={nav}>
			<Icon28CheckCircleFill/>
			<ButtonGroup
						mode="vertical"
						align="center"
						gap="m"
						style={{ width: "100%" }}
					>
						<ButtonGroup
							mode="vertical"
							align="center"
							gap="m"
							style={{ minWidth: 328 }}
						>
							<Button
								id="new_zapiska"
								stretched
								size="l"
								mode="outline"
								appearance="accent"
								onClick={() => routeNavigator.push(`/${TREBA_VIEW_PANELS.HOME}`)}
							>
								Добавить еще одну записку
							</Button>
							<Caption>или</Caption>
							<Button
								id="zapiska_payment"
								stretched
								size="l"
								mode="primary"
								appearance="positive"
								onClick={() => routeNavigator.push(`/${TREBA_VIEW_PANELS.HOME}`)}
							>
								Перейти к оплате
							</Button>
						</ButtonGroup>
					</ButtonGroup>
		</ModalCard>
	);
}