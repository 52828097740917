import React, { useState, useEffect, useContext } from "react";

import {
	Spinner,
	Placeholder,
	Button,
	CardGrid,
	Card,
	RichCell,
	usePlatform,
	Platform,
	Group,
	SimpleCell,
	Counter,
	Spacing,
} from "@vkontakte/vkui";

import { GoFunctionProp} from "../types";
import { Icon20DocumentOutline, Icon16DropdownFlippedOutline, Icon16DropdownOutline, Icon28CancelCircleOutline, Icon28CheckCircleOutline, Icon28HelpCircleOutline, Icon28RecentOutline, Icon56HistoryOutline, Icon16User, Icon56CancelCircleOutline } from "@vkontakte/icons";
import { OrderAPI } from "../api/orders";
import { NotesAPI } from "../api/notes";
import { Order } from "../types";
import { noteNum } from "../helpers";
import InfiniteScroll from "react-infinite-scroll-component";
import { UUID } from "crypto";
import './history.css';
import { HistoryContext } from "../context/HistoryContext";
import { trebaCats } from "../constants";
import { PROFILE_VIEW_PANELS } from "../routes";
import { useNotification } from '../context/NotificationContext';
import { RouterProvider, useRouteNavigator } from "@vkontakte/vk-mini-apps-router";

export const History = ({ go }: GoFunctionProp) => {
	const platform = usePlatform();
	const isVKCOM = platform === Platform.VKCOM;
	const [error, setError] = useState(false);
	const { notify, apiErrorNotify } = useNotification();
	const [currentOrder, setCurrentOrder] = useState<Order|null>(null);
	const [opened, setOpened] = useState<UUID|null>(null);
	const { updateOrderInHistory, historyItems, updateHistoryHasMore, addToHistory, hasMore, total, updateHistoryTotal, historyPage, updateHistoryPage, getOrderById } = useContext(HistoryContext);
	const [loading, setLoading] = useState(historyItems.length>0?false:true);
	const routeNavigator = useRouteNavigator();

	const statusIcons:any[] = [
		<Icon28HelpCircleOutline color="var(--vkui--color_icon_medium)" height={48} style={{marginLeft:"5px"}} />,
		<Spinner size="large" />,
		<Icon28CheckCircleOutline color="var(--vkui--color_icon_positive)" height={48} style={{marginLeft:"5px"}}/>,
		<Icon28CancelCircleOutline color="var(--vkui--color_icon_negative)" height={48} style={{marginLeft:"5px"}}/>,
		<Icon28RecentOutline color="var(--vkui--color_icon_medium)" height={48} style={{marginLeft:"5px"}}/>
	]
  
  useEffect(() => {
	if (historyItems.length===0) {
		if (!total) {
			setLoading(true);
		}
    OrderAPI.getList(1)
      .then((res) => {
			setError(false);
			if (res.status === 200) {
				updateHistoryTotal(res.data.total)
				const items = res.data.items.map(item => {
					const date = new Date(item.createdAt);
					item.createdAt = date.toLocaleString();
					return item;
				});
				addToHistory(items)
			}
			if (res.status === 204) {
				updateHistoryHasMore(false)
			}
			setLoading(false);
		}
		)
      .catch((err) => {
		setLoading(false);
		setError(true);
		//apiErrorNotify(err);
		notify("Невозможно загрузить записки.\r\nПопробуйте позже",'error');
	  }
	
	);
}
  }, []);

  useEffect(() => {
	if (total > 0) {
    	historyItems.length<total ? updateHistoryHasMore(true) : updateHistoryHasMore(false)
	}
  }, [historyItems, total]);

async function openOrder(id:UUID) {
	setOpened(null);
	let order = getOrderById(id)
	

	if (order.items) {
		setCurrentOrder(order)
	} else {
		await NotesAPI.getList(id)
      .then((res) => {
			if (res.status === 200) {
				order.items = res.data;
				updateOrderInHistory(order);
				setCurrentOrder(order)
			}
		}
		)
      .catch((err) => {
		notify("Невозможно загрузить записки.\r\nПопробуйте позже",'error');
	  }
	
	);
	}
  }
  useEffect(() => {
	if (currentOrder) {
	setOpened(currentOrder?.id);
	}
  }, [currentOrder]);
  

  const toggleOrder = (id:UUID) => {
	if (id===opened) {
		setOpened(null);
	} else {
		openOrder(id);
	}
  }

  const fetchMoreData = () => {
	OrderAPI.getList(historyPage)
      .then((res) => {
		if (res.status === 200) {
			const items = res.data.items.map(item => {
				const date = new Date(item.createdAt);
				item.createdAt = date.toLocaleString();
				return item;
			});
			addToHistory(items);
			historyItems.length>=total ? updateHistoryHasMore(true) : updateHistoryHasMore(false);
			updateHistoryPage(historyPage + 1);
		}
		if (res.status === 204) {
			updateHistoryHasMore(false);
		}
      })
      .catch((err) => {
		updateHistoryHasMore(false);
		apiErrorNotify(err);
	  });
  };
  	
  	if (loading) {
			return (
				<Placeholder
					icon={<Spinner size="large" />}
				  />
			)
	}

  	if (!loading && total===0) {
		return (
			<Placeholder
        		icon={<Icon56HistoryOutline />}
        		header="Ничего не найдено"
       		 	action={<Button size="l" onClick={() => routeNavigator.replace('/')}>Подать записку</Button>}
      		>
        		Вы еще не отправили ни одной записки.
      		</Placeholder>
		)
	}

	if (error) {
		return (
			<Placeholder
        		icon={<Icon56CancelCircleOutline />}
        		header="Ошибка"
       		 	action={<Button size="l" onClick={()=>window.location.reload()}>Обновить</Button>}
      		>
        		Невозможно загрузить записки.
      		</Placeholder>
		)
	}

	return (
		<Group>
		
				<InfiniteScroll
					dataLength={historyItems.length} 
					next={fetchMoreData}
					hasMore={hasMore}
					loader={hasMore?<Spinner />:""}
					>
					<CardGrid
						size="l"
						style={isVKCOM?{width:"70%", margin: "auto", paddingBottom:"8px"}:{paddingBottom:"8px"}}
						className="ordersContainer"
					>
						{historyItems &&
							historyItems.map((item) =>
								<Card
									key={item.id}
									mode="shadow"
									
								>
										<RichCell
											style={{paddingLeft:8, paddingRight: 2}}
											borderRadiusMode="inherit"
											before={statusIcons[item.status]}
											text={noteNum(item.count)} 
											subhead={item.createdAt}
											onClick={()=>toggleOrder(item.id)}
											after={opened===item.id?<Icon16DropdownFlippedOutline height={38} style={{marginRight:"8px"}} />:<Icon16DropdownOutline fill="var(--vkui--color_icon_secondary)" height={38} style={{marginRight:"8px"}} />}
										/>
									
									<div style={opened===item.id?{display:"block"}:{display:"none"}}>
										{currentOrder && (
											<Group className="noteList">
												{currentOrder.items?.map((note, index) => {
													return (
														<SimpleCell
															key={note.id}
															before={<Icon20DocumentOutline color={note.cat === "zdravie" ? "var(--vkui--color_icon_negative)":"var(--vkui--color_icon_medium)"} />}
															subtitle={note.sub?note.sub:trebaCats[note.cat as keyof typeof trebaCats]}
															indicator={
																<Counter size="s">
																	<Icon16User />&nbsp;{note.items?.length as number}&nbsp;
																</Counter>
															}
															onClick={() => go(`/${PROFILE_VIEW_PANELS.HOME}/history/${item.id}/${note.id}`)}
															hasActive={false}
														>
															{note.title}
														</SimpleCell>
													);
												})}
												<Spacing />
											</Group>
										)}
										{/* перенести чуть выше 	<CellButton onClick={allNotesToCart} before={<Icon28AddOutline />}>
													Добавить все записки в заявку
												</CellButton>
											
											</> */}
									</div>								
								</Card>
							)
						}
						</CardGrid>
				</InfiniteScroll>
			
			</Group>
	);
};
