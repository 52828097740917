import React, { useContext, useRef } from "react";

import {
	Button,
	Div,
	ModalPage,
	Group,
	Title,
	ButtonGroup,
} from "@vkontakte/vkui";

import {
	useParams,
	useRouteNavigator,
} from "@vkontakte/vk-mini-apps-router";
import { NavProp, GoFunctionProp } from "../types";
//import zdravie from "../img/zdravie.svg";
//import upokoenie from "../img/upokoenie.svg";
import { FavoritesContext } from "../context/FavoritesContext";
import { FAVORITE_VIEW_PANELS, FAVORITE_PANEL_MODALS } from "../routes";
import { UpokoenieTitle, ZdravieTitle } from "../components/images";

type  FavViewNoteProps = NavProp  & GoFunctionProp;

export const FavViewNoteModal = ({ nav, go }:  FavViewNoteProps) => {
	const routeNavigator = useRouteNavigator();
	const { noteId }: any = useParams({ panel: nav }) ?? null;


	const { getFavNote } = useContext(FavoritesContext);
	const note = useRef(getFavNote(noteId) ?? {})
	return (
		<ModalPage
			id={nav}
			nav={nav}
			dynamicContentHeight
			onClose={()=>routeNavigator.hideModal()}
		>
			<Group>
			<Div>
				<div style={{ textAlign: "center" }}>
					{note.current.cat === "zdravie" ? 
						<ZdravieTitle height="32px" width="auto" />:
						<UpokoenieTitle height="32px" width="auto" />
					}
					{/* <img
						key={note.current.cat}
						style={{ height: "32px", width: "auto", margin: "auto" }}
						loading="lazy"
						alt=""
						src={note.current.cat === "zdravie" ? zdravie : upokoenie}
					/> */}
						<Title level="2">{note.current.title}</Title>
						{note.current.description?<Title level="3" weight="3">{note.current.description}</Title>:""}
				</div>
				<ol className="noteNames">
					{note.current.items?.map((item, index) => {
							return (
								<li className={note.current.cat} key={index}><i>{item}</i></li>
							);
					})}
				</ol>

<ButtonGroup mode="vertical" stretched>
					<Button
						id="editFavNote"
						stretched
						size="l"
						mode="outline"
						onClick={() => go(`/${FAVORITE_VIEW_PANELS.EDIT}/${note.current.id}`)}
					>
						Редактировать
					</Button>
					<Button
						id="createNote"
						stretched
						size="l"
						mode="primary"
						appearance="positive"
						onClick={()=> routeNavigator.push(`/${FAVORITE_PANEL_MODALS.VIEW}/${note.current.id}/addnote`) }
					>
						Подать записку
					</Button>
					</ButtonGroup>
				</Div>
			
			</Group>
		</ModalPage>
	);
};