import React, { useEffect, useRef, useState } from 'react';
import { useNotification } from '../context/NotificationContext';

type YooWidgetProps = {
	token: string;
	onComplete:(successed: boolean)=>void;
};

export default function YooWidget({token, onComplete}:YooWidgetProps) {
	const { notify } = useNotification();
  	const paymentFormRef = useRef(true);
  	const [error, setError ] = useState(false);

  useEffect(() => {
    if (paymentFormRef.current) {
      const script = document.createElement('script');
      script.src = 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js';
      script.async = true;
      script.onload = initializeWidget;
      document.head.appendChild(script);
      paymentFormRef.current = false
      return () => {
        document.head.removeChild(script);
      };
    }
	//// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeWidget = () => {
    const checkout = new window.YooMoneyCheckoutWidget({
      confirmation_token: token,
      error_callback: function (err:any) {
		setError(true);
		notify('Ошибка инициализации формы оплаты','error');
      }
    });

	checkout.on('success', () => {
		onComplete(true);
		checkout.destroy();
	});

	checkout.on('fail', () => {
		onComplete(false);
		checkout.destroy();
	});


	checkout.render('payment-form');
    /* checkout.render('payment-form').then(() => {
		setReady(true);
	 }); */

  };

  if (error) {
	return (
		<div>Невозможно загрузить форму оплаты.<br />Попробуйте позже.</div>
	);
  } 

  return (
	<div id="payment-form"/>
  );
}