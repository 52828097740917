import React, {useState, useEffect} from "react";
import { useParams } from '@vkontakte/vk-mini-apps-router';

import {
	Group,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	Div,
	ScreenSpinner,
	Button,
	Spinner,
	Placeholder,
	ButtonGroup,
} from "@vkontakte/vkui";
import { DonateAPI } from "../api/donate";
import { Icon56CheckCircleOutline, Icon56CancelCircleOutline,Icon56RecentOutline, Icon56InfoOutline } from '@vkontakte/icons';

import {
	useRouteNavigator,
} from "@vkontakte/vk-mini-apps-router";

//import "./panels.css"; //???

import { NavProp, OrderStatus, PaymentStatus } from "../types";
import { UUID } from "crypto";

export const DonateStatus = ({ nav }: NavProp) => {
	const { orderID }: any = useParams({ panel: nav }) ?? {};
	const [result, setResult ] = useState<OrderStatus | null | undefined>(null);
	const [error, setError ] = useState(null);
	const [loading, setLoading ] = useState(false);
	
	const routeNavigator = useRouteNavigator();

	type PaymentStatusProps = {
		status: OrderStatus
	}
	const DonateInfo = ({ status }:PaymentStatusProps) => {
		type statusElement = {
			title: string,
			description: string,
			//color: any,
			icon: any,
			button: any
		}
		var res = {} as statusElement;
		switch (status.status) {
			case PaymentStatus.Undefined: // undefined
				res.title = "Статус неизвестен";
				res.icon = <Icon56InfoOutline color="var(--vkui--color_icon_medium)" />;
				res.button = <Button onClick={()=>getPayment(orderID)}>Обновить</Button>;

				break;
			case PaymentStatus.Pending: // pending
				res.title = "В процессе";
				res.icon = <Spinner size="large" />;
				res.button = <Button onClick={()=>getPayment(orderID)}>Обновить</Button>;
				break;
			case PaymentStatus.Successed: // successed
				res.title = "Пожертвование успешно отправлено";
				res.icon = <Icon56CheckCircleOutline color="var(--vkui--color_icon_positive)" />;
				res.button = <Button onClick={()=>routeNavigator.replace('/')}>Закрыть</Button>;
				break;
			case PaymentStatus.Canceled: // failed
				res.title = "Оплата не прошла";
				res.icon = <Icon56CancelCircleOutline color="var(--vkui--color_icon_negative)" />;
				res.description = status.desc || "";
				res.button = <Button>Попоробовать еще раз</Button>;
				break;
			case PaymentStatus.Waiting: // waiting
				res.title = "Ожидание подтверждения";
				res.icon = <Icon56RecentOutline color="var(--vkui--color_icon_medium)" />;
				res.button = <Button onClick={()=>getPayment(orderID)}>Обновить</Button>;
				break;
		}
 
		return (
			<Placeholder.Container>
				<Placeholder.Icon>
					{res.icon}
				</Placeholder.Icon>
				<Placeholder.Header>{res.title}</Placeholder.Header>
				{res.description?<Placeholder.Text>{res.description}</Placeholder.Text>:""}
				<Placeholder.Actions>
					<ButtonGroup mode="vertical" align="center">
						{res.button}
					</ButtonGroup>
				</Placeholder.Actions>
			</Placeholder.Container>
		)
	}
	const getPayment = (id:UUID) => {
		DonateAPI.get(id)
		.then((res) => {
			setResult(res.data)
		}).catch((err) => {
			setError(err)
		}).finally(() => {
			setLoading(false)
		}
		);
	}

	useEffect(() => {
		if (result === null && error === null ) {
			setLoading(true)
			getPayment(orderID);
		}
	}, []);

	return (
		<Panel nav={nav}>
			<PanelHeader
				fixed
				before={<PanelHeaderBack onClick={() => routeNavigator.back()} />}
			>
				Статус оплаты
			</PanelHeader>
			<Group>
				<Div>
					{error &&  (
						<Div>
							<Icon56CancelCircleOutline />
							Ошибка
						</Div>
					)
					}
					{loading && <ScreenSpinner state="loading" />}
					{result && <DonateInfo status={result} />}
				</Div>
			</Group>
		</Panel>
	);
};