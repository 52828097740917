import { UUID } from "crypto";

export type NavProp = {
  nav: string,
};

export type GoFunctionProp = {
  go: (path: string) => void,
};


/* export type Product = {
    title: string,
    price: number,
    unit: number,
}
export type ProductList = {
  [key: string]: Product
}
export type ProductCategory = {
  title: string,
  color: string,
  products: ProductList
}

export type ProductCategoryList = {
  [key: string]: ProductCategory
}; */

/* export type Product = {
  title: string,
  price: number,
  unit: number,
}
export type ProductList = {
  [key: string]: ProductCategory
}
export type ProductCategory = {
  title: string,
  color: string,
  products: Product[]
}

export type ProductCategoryList = {
  products: ProductList
}; */

// JSON ответы
export type InitItems = {
	zdravie: ProductCategory,
	upokoenie: ProductCategory,
}

export type InitData = {
	items: ProductList
	//items: InitItems
}


// Треба
/* export type Product = {
  id: string | '',
  title: string,
  price: number,
  unit: number,
} */

export type Product = {
	id: string | '',
	title: string,
	price: number,
	unit: number,
	variable?: boolean,
	ownVary?:boolean,
	variations?: ProductVariation[] | [],
  }

 type ProductVariation = {
	title: string,
 }


 export interface NewOrder {
	ids: UUID[],
	m: boolean
 }

export type ProductCategory = {
  title: string,
  color: string,
  products: Product[]
}

export type ProductList = {
  [key: string]: ProductCategory
};

export type ContextData = {
  products: ProductList
}

export type Error = {
	status?:number,
	code?:string,
	message?:string,
}

export type Zapiska = {
	id?:UUID,
	cat?:string,
	pid?:string, // type Product
	title?:string,
	sub?:string,
	items?:string[],
	amount?:number,
	comment?:string,
	hash?:string,
	cdt?:Date,
  }

  
export type ZapiskaRequest = {
	id?:string,
	pid?:string,
	sub?:string,
	items?:string[],
	cmnt?:string
}


export type Favorite = {
	id?:UUID,
	cat:string,
	title?:string,
	description?:string,
	items?:string[],
	hash?:string,
	createdAt?:Date
}
export type FavoriteRequest = {
	id:string,
	cat:string,
	title:string,
	desc:string,
	items:string[]
}

export type UserInfo = {
  photo_200?: string,
  first_name?: string,
  last_name?: string,
  city?: {
    title?: string,
  },
};

export type SinodikItem = {
  id:UUID,
  order?:number,
  catId:string,
  name:string,
  gender?:string,
  dateB?:string,
  dateD?:string,
  notify?:boolean,
  description?:string,
  createdAt?:Date,
}

export type SinodikData = {
  zdravie:SinodikItem[],
  upokoenie:SinodikItem[],
}

/* export type Order = {
	id:			UUID,
	count:		number,
	amount:		string,
	paid:      	boolean,
	paymentID?:	UUID,
	sent:       boolean,
	test?:		boolean,
	platform?:	string,
	createdAt: string,
} */

export type Order = {
	id:			UUID,
	count:		number,
	amount:		string,
	paymentID?:	UUID,
	test?:		boolean,
	status: 	number,
	items?:		Zapiska[],
	createdAt:	string,
}

export type OrderList = {
	items: Order[],
	total: number,
}

export type Donate = {
	amount: number,
	m: boolean,
}

/* export type OrderStatus = {
	id: UUID,
	status: string,
	test: boolean,
} */
export type OrderStatus = {
	//id: UUID,
	status: number,
	reason: string,
	desc: string,
}


export type PaymentResponse = {
	id: UUID,
	url: string,
	test: boolean,
}

export enum PaymentStatus {
	Undefined,
	Pending,
	Successed,
	Canceled,
	Waiting
}

/* type OrderItem = {
	id?:UUID,
	count?:number,
	amount?:number,
	status?:number,
	paymentId?:UUID,
	createdAt?:Date,
	sentAt?:Date,
	Items?:Zapiska[],
	
 } */